<div>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

    <p-tabMenu [model]="tabMenuItems" [activeItem]="activeTab" *ngIf="itemsChanged">
    </p-tabMenu>

    <div class="m-3">
        <ng-content></ng-content>
    </div>
</div>
