<div class="flex mb-2 justify-content-between">
    <div class="flex gap-3 mb-2">
        <p-multiSelect
            [options]="services"
            optionLabel="name"
            [(ngModel)]="selectedServices"
            placeholder="Filter by services"
            display="chip"
            [showHeader]="false"
            [style]="{width: '30em'}"
            [panelStyle]="{width:'30em'}"
            scrollHeight="40em"
            [maxSelectedLabels]="20"></p-multiSelect>

        <div>
            <p-dropdown
                [options]="rqJobs"
                [(ngModel)]="rqJob"
                [panelStyle]="{'min-width': '40em'}"
                scrollHeight='calc(100vh - 300px)'>
                <ng-template let-job pTemplate="item">
                    <div class="flex">
                        <div style="width: 25em;">{{ job.label }}</div>
                        <div style="width: 15em;" class="text-500">{{ job.time ? (job.time | localtime) : '' }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <button
                type="button" pButton pRipple icon="pi pi-refresh"
                styleClass="p-button-warn"
                (click)="loadLastRQJobsNames()"></button>
        </div>

        <p-dropdown
            [options]="logLevels"
            [(ngModel)]="selectedlogLevel"
            placeholder="Level"
            [style]="{minWidth: '8em'}"
            [panelStyle]="{minWidth:'8em'}"></p-dropdown>

        <p-button
            label="Refresh"
            icon="pi pi-refresh"
            (onClick)="loadLogs('last')"
            [loading]="loading"></p-button>
    </div>
    <div>
        <p-button
            icon="pi pi-bars"
            (onClick)="menu.toggle($event)"></p-button>
    </div>
</div>

<p-tieredMenu #menu [popup]="true" [model]="menuItems"></p-tieredMenu>

<div style="width: 100%; height: calc(100vh - {{ topOffset }}px); font-family: monospace; font-size: {{ textSize }}em; color: #eee;"
     class="relative bg-gray-900 py-2 pl-2">

    <div class="py-2 pl-2 pr-1 mr-2 absolute z-2 right-0 flex gap-1 flex-column align-items-end bg-gray-900"
         style="transition:top 0.5s ease; {{ stickySide }}">
        <div class="flex gap-1">
            <div class="px-2 py-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                        transition-colors transition-duration-200 hover:bg-gray-600 font-normal"
                 pTooltip="First page"
                 (click)="loadLogs('first')"><i class="pi pi-angle-double-left"></i></div>

            <div class="px-2 py-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                        transition-colors transition-duration-200 hover:bg-gray-600 font-normal"
                 pTooltip="Previous page"
                 (click)="loadLogs('prev')"><i class="pi pi-angle-left"></i></div>

            <div class="px-2 py-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                        transition-colors transition-duration-200 hover:bg-gray-600 font-normal"
                 pTooltip="Next page"
                 (click)="loadLogs('next')"><i class="pi pi-angle-right"></i></div>

            <div class="px-2 py-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                        transition-colors transition-duration-200 hover:bg-gray-600 font-normal"
                 pTooltip="Last page"
                 (click)="loadLogs('last')"><i class="pi pi-angle-double-right"></i></div>
        </div>
        <div class="mr-1 mt-1">
            {{ logsBegin }}..{{ logsEnd }} of {{ logsTotal }}
        </div>
    </div>

    <div style="overflow-y: scroll; height: calc(100vh - {{ topOffset }}px - 1rem);"
         (scroll)="scrolled($event)">
        <div *ngFor="let l of logs" style="display: flex;">
            <div style="width: 12em;" class="log-cell" *ngIf="columns[0]"> {{ l.time | localtime }}</div>
            <div style="width: 7em;" class="log-cell" *ngIf="columns[1]"> {{ l.host }}</div>
            <div style="width: 7em;" class="log-cell" *ngIf="columns[2]" pTooltip="{{ l.path }}:{{ l.lineno }}"> {{ l.path }}:{{ l.lineno }}</div>
            <div style="width: 7em;" class="log-cell" *ngIf="columns[3]"> {{ l.service }}</div>
            <div style="width: 9em;" class="log-cell" *ngIf="columns[4]" pTooltip="{{ l.tool }}"> {{ l.tool }}</div>
            <div style="width: 4em;" class="log-cell" *ngIf="columns[5]"> {{ l.step }}</div>
            <div style="width: 4em;" class="log-cell" *ngIf="columns[6]"> {{ l.branch }}</div>
            <div style="width: 4em;" class="log-cell" *ngIf="columns[7]"> {{ l.flow_kind }}</div>
            <div style="width: 4em;" class="log-cell" *ngIf="columns[8]"> {{ l.flow }}</div>
            <div style="width: 4em;" class="log-cell" *ngIf="columns[9]"> {{ l.run }}</div>
            <div style="width: 4em;" class="log-cell" *ngIf="columns[10]"> {{ l.job }}</div>
            <div style="width: 4em;" class="log-cell" *ngIf="columns[11]"> {{ l.agent }}</div>
            <div style="width: 4em;" class="log-cell"  *ngIf="columns[12]"
                 [ngClass]="'level-' + l.level.slice(0, 4).toLowerCase()"> {{ l.level.slice(0, 4) }}</div>
            <div style="white-space: pre-wrap; word-break: break-word;"
                 [innerHTML]="prepareLogLine(l.message) | noSanitize">
            </div>
        </div>
    </div>
</div>
