<h2>New Flow</h2>

<div style="margin-bottom: 10px;">
  Arguments for new flow for <b>{{kind}}</b> branch <b>{{branch.name}}</b>.
</div>
<div *ngFor="let section of params" style="margin-bottom: 10px;">
  <span style="font-size: 1.2rem; font-weight: bold;">{{section.name}}</span>
  <table>
    <tr>
      <th style="width: 20rem;"></th>
      <th style="width: 20rem;"></th>
    </tr>

    <tr *ngFor="let p of section.params">
      <td style="text-align: right; padding-right: 10px;">
        {{p.name}}
        <br>
        <span style="font-size: 0.8rem; font-weight: 100;">
          {{p.description}}
          <span *ngIf="p.default">
            <br>Default value is {{p.default}}.
          </span>
        </span>
      </td>
      <td>
        <input type="text" pInputText [(ngModel)]="args[section.name][p.name]"/>
      </td>
    </tr>
  </table>
</div>
<p-button label="Submit"
          icon="pi pi-check"
          (onClick)="submitFlow()"
          [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
          [pTooltip]="auth.permTip(projectId, 'pwrusr')"
          [loading]="waiting"></p-button>
