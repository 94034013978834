<div>
  <div style="margin-bottom: 5px;">
    <a routerLink="/branches/{{branchId}}/{{kind}}/flows/new">
      <p-button label="Run Flow" icon="pi pi-caret-right" [style]="{'margin-right': '5px'}"
            [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
            [pTooltip]="auth.permTip(projectId, 'pwrusr')"></p-button>
    </a>
    <p-button label="Refresh" icon="pi pi-refresh" (onClick)="refresh()" [style]="{'margin-right': '10px'}" [loading]="refreshing"></p-button>
    <!--
        <p-multiSelect [options]="stagesAvailable" [(ngModel)]="selectedStages" optionLabel="name"></p-multiSelect>
        <p-multiSelect [options]="stagesAvailable" optionLabel="name" [filter]="false" style="height: 33px;" [style]="{minWidth: '12em'}" [panelStyle]="{minWidth: '12em'}"></p-multiSelect>
    -->
    <p-dropdown [options]="stagesAvailable" optionLabel="name" (onChange)="filterStages($event)"></p-dropdown>
  </div>

  <div *ngFor="let flow of flows" class="grid p-align-stretch">
    <div class="col-1">
        <div style="display: flex; justify-content: space-between;">
          <a routerLink="/flows/{{flow.id}}" style="color: unset; display: block;">
            <b style="font-size: 1.2rem;">{{ flow.label }}</b>
            <div style="font-size: 0.7rem;">
              <div *ngIf="flow.trigger && flow.trigger.pull_request && flow.trigger.pull_request.head && flow.trigger.pull_request.head.ref">
                {{ flow.trigger.pull_request.head.ref }}<br>
                by {{ flow.trigger.pull_request.user.login }}
              </div>
              <div>{{flow.created | localtime}}</div>
              <div>{{flow.duration}}
                <span *ngIf="flow.state === 'in-progress'">
                  <i class="pi pi-spin pi-spinner" style="font-size: 16px; vertical-align: text-top;"></i>
                </span>
              </div>
            </div>
          </a>
          <div style="margin: 4px 0 0 6px; color: #888;">
            <div style="margin: 4px 0 0 0;" *ngIf="flow.artifacts && flow.artifacts.public && flow.artifacts.public.count > 0">
              <a routerLink="/flows/{{flow.id}}/artifacts" style="color: unset; display: block;">
                <i class="pi pi-download" style="font-size: 1.6em;"
                   pTooltip="Files: {{ flow.artifacts.public.count }}<br>Size: {{ humanFileSize(flow.artifacts.public.size) }}"
                   tooltipStyleClass="artifacts-tooltip" [escape]="false" hideDelay="1000"></i>
              </a>
            </div>
            <div style="margin: 4px 0 0 0; text-align: center;" *ngIf="hasFlowCommits(flow)">
              <i class="fa fa-code-branch" style="font-size: 1.6em;"
                 [pTooltip]="getFlowCommits(flow)"
                 tooltipStyleClass="commits-tooltip" [escape]="false" hideDelay="2000"></i>
            </div>
          </div>
        </div>
    </div>
    <div class="col" style="display: grid; grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr)); gap: 6px;">
      <ng-container *ngFor="let run of flow.runs">
        <app-run-box *ngIf="filterStageName === 'All' || run.stage_name === filterStageName"
                     [projectId]="projectId" [run]="run" [flowId]="flow.id" (stageRun)="onStageRun($event)">
        </app-run-box>
      </ng-container>
    </div>
  </div>

  <p-paginator [rows]="10"
               [totalRecords]="totalFlows"
               [rowsPerPageOptions]="[10,20,30,50,100]"
               [showCurrentPageReport]="true"
               (onPageChange)="paginateFlows($event)"></p-paginator>

</div>
