  <h3>Agents Deployment</h3>
  <p-selectButton [options]="deploymentMethods"
                  [(ngModel)]="deployment.method"
                  optionLabel="name"
                  optionValue="val"
                  (onChange)="deploymentMethodChange()"></p-selectButton>

  <div style="margin: 12px 6px 18px 12px;">
    <div *ngIf="deployment.method === 0">
      <i>No extra configuration needed.</i>
    </div>

    <!-- AWS EC2 -->
    <div *ngIf="deployment.method === 2" class="flex align-items-start">
      <!-- column 1 -->
      <div class="mr-4">
        <h4>General</h4>
        <div class="field grid">
          <label for="region" class="col-fixed" style="width:100px">Region</label>
          <div class="col">
            <p-dropdown id="region"
                        [options]="awsRegions"
                        [(ngModel)]="deployment.aws.region"
                        optionLabel="RegionName"
                        optionValue="RegionName"
                        [style]="{width: '100%'}"
                        [filter]="true"
                        (onChange)="regionChange()"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="instancesLimit" class="col-fixed" style="width:100px">Instances Limit</label>
          <div class="col">
            <p-inputNumber [(ngModel)]="deployment.aws.instances_limit" [showButtons]="true"
                           inputId="instancesLimit"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="0"
                           size="4"
                           maxlength="5"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>

          </div>
        </div>
        <div class="field grid">
          <label for="defaultImage" class="col-fixed" style="width:100px">Default AMI Image</label>
          <div class="col">
            <input id="defaultImage" type="text" [(ngModel)]="deployment.aws.default_image" pInputText>
          </div>
        </div>
      </div>

      <!-- column 2 -->
      <div class="mr-4">
        <h4>VM Options</h4>
        <div class="field grid">
          <label for="instanceType" class="col-fixed" style="width:100px">Instance Type</label>
          <div class="col">
            <p-dropdown id="instanceType"
                        [options]="awsInstanceTypes"
                        [(ngModel)]="deployment.aws.instance_type"
                        optionLabel="InstanceType"
                        optionValue="InstanceType"
                        [style]="{width: '10em'}"
                        scrollHeight="300px"
                        [filter]="true"></p-dropdown>
          </div>
        </div>
        <div class="field-checkbox">
          <p-checkbox [(ngModel)]="deployment.aws.spot_instance" binary="true" inputId="spotInstance"></p-checkbox>
          <label for="spotInstance">Spot Instance</label>
        </div>
        <div class="field-checkbox">
          <p-checkbox [(ngModel)]="deployment.aws.monitoring" binary="true" inputId="monitoring"></p-checkbox>
          <label for="monitoring">Monitoring</label>
        </div>
        <div class="field-checkbox">
          <p-checkbox [(ngModel)]="deployment.aws.cpu_credits_unlimited" binary="true" inputId="cpuCreditsUnlimited"></p-checkbox>
          <label for="cpuCreditsUnlimited">CPU Credits Unlimited</label>
        </div>
        <div class="field grid">
          <label for="diskSize" class="col-fixed" style="width:100px">Disk Size</label>
          <div class="col">
            <p-inputNumber [(ngModel)]="deployment.aws.disk_size" [showButtons]="true"
                           inputId="diskSize"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="0"
                           size="4"
                           maxlength="5"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>

          </div>
        </div>
      </div>

      <!-- column 3 -->
      <div class="mr-4">
        <h4>Init Script</h4>
        <textarea [(ngModel)]="deployment.aws.init_script" [rows]="10" [cols]="50" style="font-family: monospace;" pInputTextarea></textarea>
      </div>

      <!-- column 4 -->
      <div>
        <h4>Destruction</h4>
        <div style="margin: 5px;">
          <label for="maxJobsIn">Destroy VM after
            <p-inputNumber [(ngModel)]="deployment.aws.destruction_after_jobs" [showButtons]="true"
                           inputId="maxJobsIn"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="1"
                           size="2"
                           maxlength="4"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>
            jobs (0 means disabled)
          </label>
        </div>
        <div style="margin: 5px;">
          <label for="idleTimeIn">Destroy VM after
            <p-inputNumber [(ngModel)]="deployment.aws.destruction_after_time" [showButtons]="true"
                           inputId="idleTimeIn"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="0"
                           [step]="5"
                           size="2"
                           maxlength="5"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>
            minutes of idle time (0 means disabled)
          </label>
        </div>
      </div>
    </div>

    <!-- AWS ECS Fargate -->
    <div *ngIf="deployment.method === 3" class="flex align-items-start">
      <!-- column 1 -->
      <div class="mr-4">
        <h4>General</h4>
        <div class="field grid">
          <label for="ecs-fg-region" class="col-fixed" style="width:100px">Region</label>
          <div class="col">
            <p-dropdown id="ecs-fg-region"
                        [options]="awsRegions"
                        [(ngModel)]="deployment.aws_ecs_fargate.region"
                        optionLabel="RegionName"
                        optionValue="RegionName"
                        [style]="{width: '100%'}"
                        [filter]="true"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="ecs-fg-instancesLimit" class="col-fixed" style="width:100px">Instances Limit</label>
          <div class="col">
            <p-inputNumber [(ngModel)]="deployment.aws_ecs_fargate.instances_limit" [showButtons]="true"
                           inputId="ecs-fg-instancesLimit"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="0"
                           size="4"
                           maxlength="5"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>

          </div>
        </div>
      </div>

      <!-- column 2 -->
      <div class="mr-4">
        <h4>ECS Task Options</h4>
        <div class="field grid">
          <label for="ecs-fg-cluster" class="col-fixed" style="width:100px">Cluster</label>
          <div class="col">
              <input type="text" pInputText [(ngModel)]="deployment.aws_ecs_fargate.cluster" id="ecs-fg-cluster"/>
          </div>
        </div>

        <div class="field grid">
          <label for="ecs-fg-subnets" class="col-fixed" style="width:100px">Subnets</label>
          <div class="col">
              <input type="text" pInputText [(ngModel)]="deployment.aws_ecs_fargate.subnets"/>
          </div>
        </div>

        <div class="field grid">
          <label for="ecs-fg-security_groups" class="col-fixed" style="width:100px">Security Groups</label>
          <div class="col">
              <input type="text" pInputText [(ngModel)]="deployment.aws_ecs_fargate.security_groups" id="ecs-fg-security_groups"/>
          </div>
        </div>
      </div>

    </div>

    <!-- AZURE VM -->
    <div *ngIf="deployment.method === 4" class="flex align-items-start">
      <!-- column 1 -->
      <div class="mr-4">
        <h4>General</h4>
        <div class="field grid">
          <label for="azure-vm-location" class="col-fixed" style="width:100px">Location</label>
          <div class="col">
            <p-dropdown id="azure-vm-location"
                        [options]="azureLocations"
                        [(ngModel)]="deployment.azure_vm.location"
                        [style]="{width: '100%'}"
                        [filter]="true"
                        (onChange)="azureLocationChange()"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="instancesLimit" class="col-fixed" style="width:100px">Instances Limit</label>
          <div class="col">
            <p-inputNumber [(ngModel)]="deployment.azure_vm.instances_limit" [showButtons]="true"
                           inputId="instancesLimit"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="0"
                           size="4"
                           maxlength="5"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>

          </div>
        </div>
        <div class="field grid">
          <label for="defaultImage" class="col-fixed" style="width:100px">Default Image</label>
          <div class="col">
            <input id="defaultImage" type="text" [(ngModel)]="deployment.azure_vm.default_image" pInputText>
          </div>
        </div>
      </div>

      <!-- column 2 -->
      <div class="mr-4">
        <h4>VM Options</h4>
        <div class="field grid">
          <label for="vmSize" class="col-fixed" style="width:100px">VM Size</label>
          <div class="col">
            <p-dropdown id="vmSize"
                        [options]="azureVmSizes"
                        [(ngModel)]="deployment.azure_vm.vm_size"
                        optionLabel="name"
                        optionValue="name"
                        [style]="{width: '10em'}"
                        scrollHeight="300px"
                        [filter]="true"></p-dropdown>
          </div>
        </div>
      </div>

      <!-- column 3 -->
      <div class="mr-4">
        <h4>Init Script</h4>
        <textarea [(ngModel)]="deployment.azure_vm.init_script" [rows]="10" [cols]="50" style="font-family: monospace;" pInputTextarea></textarea>
      </div>

      <!-- column 4 -->
      <div>
        <h4>Destruction</h4>
        <div style="margin: 5px;">
          <label for="maxJobsIn">Destroy VM after
            <p-inputNumber [(ngModel)]="deployment.azure_vm.destruction_after_jobs" [showButtons]="true"
                           inputId="maxJobsIn"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="1"
                           size="2"
                           maxlength="4"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>
            jobs (0 means disabled)
          </label>
        </div>
        <div style="margin: 5px;">
          <label for="idleTimeIn">Destroy VM after
            <p-inputNumber [(ngModel)]="deployment.azure_vm.destruction_after_time" [showButtons]="true"
                           inputId="idleTimeIn"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="0"
                           [step]="5"
                           size="2"
                           maxlength="5"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>
            minutes of idle time (0 means disabled)
          </label>
        </div>
      </div>
    </div>


    <!-- Kubernetes -->
    <div *ngIf="deployment.method === 5" class="flex align-items-start">
      <!-- column 1 -->
      <div class="mr-6">
        <h4>General</h4>

        <div class="field grid">
          <label for="k8s-instancesLimit" class="col-fixed" style="width:100px">Instances Limit</label>
          <div class="col">
            <p-inputNumber [(ngModel)]="deployment.kubernetes.instances_limit" [showButtons]="true"
                           inputId="k8s-instancesLimit"
                           buttonLayout="horizontal"
                           spinnerMode="horizontal"
                           [min]="0"
                           size="4"
                           maxlength="5"
                           decrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonClass="p-button-secondary p-button-outlined"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus">
            </p-inputNumber>
          </div>
        </div>

      </div>

    </div>


  </div>
