<h2>New Run of stage {{stage.name}}</h2>

<div style="margin-bottom: 10px;">
  Arguments for new run of stage <b>{{stage.name}}</b> in flow <b>{{flow.id}}</b>.
</div>

<table>
  <tr>
    <th style="width: 20rem;"></th>
    <th style="width: 20rem;"></th>
  </tr>

  <tr *ngFor="let p of params">
    <td style="text-align: right; padding-right: 10px;">
      {{p.name}}
      <br>
      <span style="font-size: 0.8rem; font-weight: 100;">
        {{p.description}}
        <span *ngIf="p.default">
          <br>Default value is {{p.default}}.
        </span>
      </span>
    </td>
    <td>
      <input type="text" pInputText [(ngModel)]="args[p.name]"/>
    </td>
  </tr>
</table>

<p-button label="Submit" icon="pi pi-check" (onClick)="submitRun()"
          [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
          [pTooltip]="auth.permTip(projectId, 'pwrusr')"></p-button>
