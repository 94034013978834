<!-- change branch names dialog -->
<p-dialog header="Branch display and repo names" [(visible)]="branchNameDlgVisible" [style]="{width: '30rem'}">
    <div class="field grid">
        <label for="branchDisplayName" class="col-fixed" style="width: 10rem;">Display Name</label>
        <div class="col">
            <input id="branchDisplayName" type="text" [(ngModel)]="newBranchDisplayName" (keydown)="branchNameKeyDown($event)" autofocus pInputText>
        </div>
    </div>
    <div class="field grid">
        <label for="branchRepoName" class="col-fixed" style="width: 10rem;">Name in the Repo</label>
        <div class="col">
            <input id="branchRepoName" type="text" [(ngModel)]="newBranchRepoName" (keydown)="branchNameKeyDown($event)" pInputText>
        </div>
    </div>

    <p-footer>
        <button type="button" (click)="cancelBranchNameChange()" pButton icon="pi pi-times" label="Cancel" class="p-button-outlined p-button-secondary"></button>
        <button type="button" (click)="saveBranchName()" pButton icon="pi pi-check" label="Change"></button>
    </p-footer>
</p-dialog>


<!-- fork branch dialog -->
<p-dialog header="Fork branch {{branch.name}}" [(visible)]="forkBranchDlgVisible">
    <!-- name fields -->
    <div class="field grid">
        <label for="forkBranchDisplayName" class="col-fixed" style="width: 10rem;">Display Name</label>
        <div class="col">
            <input id="forkBranchDisplayName" type="text" [(ngModel)]="forkBranchDisplayName" (keydown)="forkBranchKeyDown($event)" autofocus pInputText>
        </div>
    </div>
    <div class="field grid">
        <label for="forkBranchRepoName" class="col-fixed" style="width: 10rem;">Name in the Repo</label>
        <div class="col">
            <input id="forkBranchRepoName" type="text" [(ngModel)]="forkBranchRepoName" (keydown)="forkBranchKeyDown($event)" pInputText>
        </div>
    </div>

    <!-- forking model -->
    <div style="width: 100%; margin-bottom: 15px;">
        <img src="/assets/forking-branch.png" style="width: 700px; display: block; margin: 0 auto; border: 1px solid #aaa; box-shadow: 0 3px 10px #ddd; padding: 15px;">
    </div>
    <div class="field-radiobutton">
        <p-radioButton name="forkingModel" value="model-1" [(ngModel)]="forkingModel" inputId="forkingModel1"></p-radioButton>
        <label for="forkingModel1">Model 1</label>
    </div>
    <div class="field-radiobutton">
        <p-radioButton name="forkingModel" value="model-2" [(ngModel)]="forkingModel" inputId="forkingModel2"></p-radioButton>
        <label for="forkingModel2">Model 2</label>
    </div>

  <p-footer>
    <button type="button" (click)="cancelForkBranch()" pButton icon="pi pi-times" label="Cancel" class="p-button-outlined p-button-secondary"></button>
    <button type="button" (click)="forkBranch()" pButton icon="pi pi-check" label="Fork"></button>
  </p-footer>
</p-dialog>


<!-- new stage dialog -->
<p-dialog header="New Stage in {{branch.name}} branch" [(visible)]="newStageDlgVisible">
  <input type="text" pInputText [(ngModel)]="stageName" (keydown)="newStageKeyDown($event)" autofocus/>
  <p-footer>
    <button type="button" (click)="cancelNewStage()" pButton icon="pi pi-times" label="Cancel" class="p-button-outlined p-button-secondary"></button>
    <button type="button" (click)="addNewStage()" pButton icon="pi pi-check" label="Add"></button>
  </p-footer>
</p-dialog>


<!-- schema validation dialog -->
<p-dialog header="Schema Validation" [(visible)]="schemaCheckDisplay" [modal]="true" [responsive]="true"
          [style]="{minWidth: '400px'}" [minY]="70" [maximizable]="true">
  <pre *ngIf="schemaCheckContent.error">{{ schemaCheckContent.error }}</pre>
  <div>
    <ngx-codemirror *ngIf="schemaCheckContent.schema"
                    id="ngx-cm-json"
                    #cmEditor
                    [(ngModel)]="schemaCheckContent.schema"
                    [options]="codeMirrorJsonOpts"></ngx-codemirror>
  </div>

  <p-footer style="display: flex; justify-content: space-between; align-items: center; margin-top: 1em;">
    <div *ngIf="schemaCheckContent.schema" style="color: green;font-weight: bold;font-size: 1.4em;">
      <i class="pi pi-check" style="vertical-align: text-bottom;"></i>
      All ok
    </div>
    <div *ngIf="schemaCheckContent.error" style="color: red;font-weight: bold;font-size: 1.4em;">
      <i class="pi pi-exclamation-triangle" style="vertical-align: text-bottom;"></i>
      Error in schema code
    </div>
    <button type="button" pButton icon="pi pi-close" (click)="schemaCheckDisplay=false" label="Close"></button>
  </p-footer>
</p-dialog>


<!-- prepare step dialog -->
<p-dialog header="Prepare Step" [(visible)]="prepareStepDlgVisible" [maximizable]="true"
          [style]="{width: '80vw'}">

    <div style="height: 70vh;" *ngIf="stepTool">
        <div class="flex justify-content-between">
            <div class="flex align-items-center">
                <p-dropdown [options]="stepTools"
                            [(ngModel)]="stepTool"
                            optionLabel="name"
                            [style]="{width: '10em'}"
                            [panelStyle]="{'min-width': '40em'}"
                            scrollHeight='50vh'
                            (onChange)="changeTool()">
                    <ng-template let-tool pTemplate="item">
                        <div class="flex">
                            <div class="col-2 font-semibold">{{tool.name}}</div>
                            <div class="col-10">{{tool.shortDescr}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>

                <p-dropdown [options]="stepToolVersions"
                            [(ngModel)]="stepToolVersion"
                            optionLabel="version"
                            [style]="{width: '10em'}"
                            [panelStyle]="{'min-width': '40em'}"
                            scrollHeight='50vh'
                            (onChange)="generateStep()">
                    <ng-template let-tool pTemplate="item">
                        <div class="flex">
                            <div class="col-2 font-semibold">{{tool.version}}</div>
                            <div class="col-10">{{tool.shortDescr}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>

                <div class="ml-3">
                    {{ stepTool.descr }}
                </div>
            </div>

            <div>
                <a pButton label="Schema Help" href="https://kraken.ci/docs/schema" class="p-button-text" target="blank" icon="pi pi-question-circle" style="padding: 0 16px 4px 16px;"></a>
                <a pButton label="Schema Reference" href="https://kraken.ci/docs/schema-ref" class="p-button-text" target="blank" icon="pi pi-question-circle" style="padding: 0 16px 4px 16px;"></a>
            </div>
        </div>

        <div class="mt-3">
            <table class="step-help-table">
                <ng-container *ngFor="let f of stepFields">
                    <tr *ngIf="f.name !== 'tool'">
                        <td class="step-help-name" [ngClass]="{'text-400': f.base}">{{ f.name }}</td>
                        <td class="step-help-type">{{ f.type }}</td>
                        <td class="step-help-value">
                            <input type="text" [(ngModel)]="f.value" pInputText (keyup)="generateStep2()" class="p-inputtext-sm" *ngIf="f.type === 'string'" />
                            <input type="text" [(ngModel)]="f.value" pInputText (keyup)="generateStep2()" class="p-inputtext-sm" *ngIf="f.type === 'integer'" pKeyFilter="pint" />
                            <input type="text" [(ngModel)]="f.value" pInputText (keyup)="generateStep2()" class="p-inputtext-sm" *ngIf="f.type === 'boolean'" />
                        </td>
                        <td>{{ f.descr }}</td>
                    </tr>
                </ng-container>
            </table>
        </div>

        <h3>Generated Step Code</h3>
        <div>
            <pre>{{ generatedStep }}</pre>
        </div>
<!--        <input type="text" pInputText [(ngModel)]="stageName" (keydown)="newStageKeyDown($event)" autofocus/> -->
    </div>
  <p-footer>
    <button type="button" (click)="prepareStepDlgVisible = false" pButton icon="pi pi-times" label="Cancel" class="p-button-outlined p-button-secondary"></button>
    <button type="button" (click)="copyGeneratedStep()" pButton icon="pi pi-copy" label="Copy to Clipboard"></button>
  </p-footer>
</p-dialog>


<!-- move branch dialog -->
<p-dialog header="Move Branch to Another Project" [(visible)]="moveBranchDlgVisible" [modal]="true" [responsive]="true"
          [style]="{minWidth: '400px'}">

  <div class="mb-2">Select destination project:</div>
  <p-listbox [options]="projects" optionLabel="name" [(ngModel)]="targetProject" [listStyle]="{'max-height': '280px'}"></p-listbox>

  <p-footer>
    <button type="button" (click)="cancelMoveBranch()" pButton icon="pi pi-times" label="Cancel" class="p-button-outlined p-button-secondary"></button>
    <button type="button" (click)="moveBranch()" pButton icon="pi pi-check" label="Move"></button>
  </p-footer>
</p-dialog>


<!-- page -->
<div style="margin-bottom: 15px; display: flex; justify-content: space-between;">
  <div style="display: flex; align-items: baseline;">

    <!-- branch name -->
    <div (click)="displayBranchNameEdit()">
        <span style="font-size: 2rem; font-weight: bold;">
            {{branch.name}}
        </span>
        <span style="font-size: 1rem; padding-left: 0.3rem;" *ngIf="branch.branch_name && branch.branch_name !== branch.name && branch.branch_name.length > 0">
            {{branch.branch_name}}
        </span>
        <i class="fa fa-edit" style="font-size: 1.3rem; padding-left: 0.5rem; color: #888;"
           pTooltip="Edit display and repo branch names"></i>
    </div>

    <!-- links to results -->
    <div style="margin-left: 40px;">
      <a routerLink="/branches/{{branchId}}/ci">CI Results</a>   &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;   <a routerLink="/branches/{{branchId}}/dev">Dev Results</a>
    </div>

    <!-- buttons to trigger a new flow -->
    <a routerLink="/branches/{{branchId}}/ci/flows/new">
      <button type="button" pButton icon="pi pi-caret-right" label="Run CI Flow" style="margin: 0 10px 0 100px;"
              [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
              [pTooltip]="auth.permTip(projectId, 'pwrusr')"></button>
    </a>
    <a routerLink="/branches/{{branchId}}/dev/flows/new">
      <button type="button" pButton icon="pi pi-caret-right" label="Run Dev Flow"
              [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
              [pTooltip]="auth.permTip(projectId, 'pwrusr')"
              ></button>
    </a>
  </div>

  <!-- aligned to right -->
  <div style="display: flex; align-items: baseline;">
    <!-- fork button -->
    <p-button label="Fork Branch" icon="fa fa-code-branch" styleClass="p-button-outlined" (onClick)="showForkBranchDialog()"
              [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
              [pTooltip]="auth.permTip(projectId, 'pwrusr')"
              [ngStyle]="{'margin-right': '10px'}"></p-button>

    <!-- Move button -->
    <p-button label="Move Branch" icon="pi pi-sign-in" styleClass="p-button-warning p-button-outlined" (onClick)="showMoveBranchDialog()"
              [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
              [pTooltip]="auth.permTip(projectId, 'pwrusr')"
              [ngStyle]="{'margin-right': '10px'}"></p-button>

    <!-- archive button -->
    <p-button label="Archive Branch" icon="pi pi-trash" styleClass="p-button-danger p-button-outlined" (onClick)="deleteBranch()"
              [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
              [pTooltip]="auth.permTip(projectId, 'pwrusr')"></p-button>
  </div>
</div>

<app-tabbed-page baseLinkUrl="/branches/{{ branchId }}" (tabChanged)="handleTabChange($event)">

  <!-- Stages TAB -->
  <app-tabbed-page-tab label="Stages">
    <div class="grid">
      <div class="col-3">
        <button type="button" (click)="newStage()" pButton icon="pi pi-plus" label="New Stage"
                [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
                [pTooltip]="auth.permTip(projectId, 'pwrusr')"></button>
        <div *ngFor="let stage of branch.stages" style="margin: 6px 0 12px 0; cursor: pointer;" class="round-shadow {{ stage.selectedClass }} {{ stage.enabled ? '' : 'disabled-stage' }}" (click)="selectStage(stage)">
          <div style="font-size: 1.1rem; font-weight: bold; margin-bottom: 4px;">{{stage.name}}</div>
          <div style="margin-bottom: 6px;">{{stage.description}}</div>
          <div style="font-size: 1.05rem;">Parent: {{stage.schema.parent}}</div>
          <div style="font-size: 1.05rem;">Trigger:
            <div *ngIf="stage.schema.triggers.parent === false || stage.schema.triggers.parent === true">
              - parent: {{stage.schema.triggers.parent}}
            </div>
            <div *ngIf="stage.schema.triggers.interval">
              - interval: {{stage.schema.triggers.interval}}
            </div>
            <div *ngIf="stage.schema.triggers.cron">
              - cron: {{stage.schema.triggers.cron}}
            </div>
            <div *ngIf="stage.schema.triggers.date">
              - date: {{stage.schema.triggers.date}}
            </div>
          </div>
          <div *ngIf="stage.schema.parameters" style="font-size: 1.05rem;">Parameters: {{stage.schema.parameters.length}}</div>
        </div>
      </div>
      <div class="col-9" style="padding-left: 40px;" *ngIf="branch.stages && branch.stages.length">
        <div>
          <div class="flex">
            <div class="m-2" style="min-width: 18em;">
              <p-inplace #stageNameInplace (onActivate)="stageNameInplaceActivated()">
                <span pInplaceDisplay style="font-size: 1.5rem; font-weight: bold;">
                  <span style="color: #999;">Stage</span> {{stage.name}}
                </span>
                <span pInplaceContent>
                  <input type="text" pInputText [(ngModel)]="newStageName" (keydown)="stageNameKeyDown($event, stageNameInplace)"/>
                </span>
              </p-inplace>

              <p-inplace #stageDescrInplace (onActivate)="stageDescrInplaceActivated()">
                <span pInplaceDisplay>
                  {{stage.description || '(empty)'}}
                </span>
                <span pInplaceContent>
                  <textarea pInputTextarea [(ngModel)]="newStageDescr" (keydown)="stageDescrKeyDown($event, stageDescrInplace)"  [rows]="5" [cols]="40"></textarea>
                </span>
              </p-inplace>
            </div>
            <div class="m-2" style="margin-top: 25px;">
              <p-checkbox [(ngModel)]="stage.enabled" binary="true" label="Enabled"></p-checkbox>
            </div>
            <div class="m-2" style="margin-top: 25px;">
              <p-checkbox [(ngModel)]="stage.schema_from_repo_enabled" binary="true" label="Schema from Repo"></p-checkbox>
            </div>
            <div class="m-2" style="margin-top: 5px;" *ngIf="stage.repo_version">
              Current rev: {{ stage.repo_version.slice(0, 8) }}
            </div>
            <div class="m-2">
              <a pButton label="Schema Help" href="https://kraken.ci/docs/schema" class="p-button-text" target="blank" icon="pi pi-question-circle" style="padding: 0 16px 4px 16px;"></a>
              <a pButton label="Schema Reference" href="https://kraken.ci/docs/schema-ref" class="p-button-text" target="blank" icon="pi pi-question-circle" style="padding: 0 16px 4px 16px;"></a>
            </div>
          </div>

          <p-tabView (onChange)="handleSubTabChange($event)">
            <p-tabPanel header="Schema">
              <div style="height: calc(100vh - 520px); padding: 5px 0 10px 0;">
                <ngx-codemirror
                  id="ngx-cm"
                  [(ngModel)]="stage.schema_code"
                  [options]="codeMirrorOpts"></ngx-codemirror>
              </div>
              <div class="flex" style="gap: 1em">
                  <!--
                  <button type="button" (click)="checkStageSchema()" pButton icon="pi pi-plus" label="Prepare Stage"></button>
                  <button type="button" (click)="checkStageSchema()" pButton icon="pi pi-plus" label="Prepare Job"></button>
                  -->
                  <button type="button" (click)="showPrepareStepDialog()" pButton icon="pi pi-plus" label="Prepare Step"></button>
                  <button type="button" (click)="checkStageSchema()" pButton icon="pi pi-eye" label="Check Schema"></button>
              </div>
            </p-tabPanel>

            <p-tabPanel header="Schema from Repository">
              <div class="form-style-2">
                <form [formGroup]="schemaFromRepoForm">
                  <label for="repo_url"><span>Repo URL</span>
                    <input type="text" class="input-field" name="repo_url" pInputText formControlName="repo_url"/>
                  </label>

                  <label for="repo_branch"><span>Branch</span>
                    <input type="text" class="input-field" name="repo_branch" pInputText formControlName="repo_branch"/>
                  </label>

                  <label for="repo_access_token"><span>Access Token</span>
                    <input type="text" class="input-field" name="repo_access_token" pInputText formControlName="repo_access_token"/>
                  </label>

                  <label for="schema_file"><span>Schema File</span>
                    <input type="text" class="input-field" name="schema_file" pInputText formControlName="schema_file"/>
                  </label>

                  <label for="repo_refresh_interval"><span>Refresh Interval</span>
                    <input type="text" class="input-field" name="repo_refresh_interval" pInputText formControlName="repo_refresh_interval"/>
                  </label>
                  <label for="git_clone_params"><span>Git Clone Params</span>
                    <input type="text" class="input-field" name="git_clone_params" pInputText formControlName="git_clone_params"/>
                  </label>
                </form>
              </div>
            </p-tabPanel>

            <p-tabPanel header="Schedule">
              <div *ngIf="!stage || !stage.schedules || stage.schedules.length === 0">
                   No schedules for this stage.
              </div>
              <div *ngIf="stage && stage.schedules && stage.schedules.length > 0">
                <div *ngFor="let s of stage.schedules" style="margin-bottom: 16px;">
                  <div style="margin-bottom: 4px; font-weight: bold; font-size: 1.1em;">
                    {{ s.name }}
                  </div>
                  <div style="margin-bottom: 4px;">
                    Job ID: {{ s.job_id }}
                  </div>
                  <div style="margin-bottom: 4px;">
                    Next Run Time: {{ s.next_run_time ? (s.next_run_time | localtime) : '-' }}
                  </div>
                </div>
              </div>
            </p-tabPanel>

            <p-tabPanel header="Data">
                <h3>Job Data</h3>
                <pre>{{ stageData }}</pre>
            </p-tabPanel>

          </p-tabView>
        </div>

        <!-- messages -->
        <div *ngIf="stage.repo_state === 1" style="margin-bottom: 10px;">
          <p-message severity="info" text="loading schema from repo in progress"></p-message>
        </div>

        <div *ngIf="saveErrorMsg.length > 0">
          <p-messages severity="error">
            <ng-template pTemplate>
              <div [innerHTML]="saveErrorMsg"></div>
            </ng-template>
          </p-messages>
        </div>

        <!-- buttons -->
        <div>
          <button type="button" (click)="saveStage()" pButton icon="pi pi-check" label="Save Stage" style="margin: 0 30px 0 0;"
                  [disabled]="stage.repo_state === 1 || !auth.hasPermission(projectId, 'pwrusr')"
                  [pTooltip]="auth.permTip(projectId, 'pwrusr')"></button>
          <button type="button" (click)="deleteStage()" pButton icon="pi pi-trash" class="p-button-danger p-button-outlined" label="Delete Stage"
                  [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
                  [pTooltip]="auth.permTip(projectId, 'pwrusr')"></button>
        </div>

      </div>
      <div class="col-9" style="padding-left: 40px;" *ngIf="!branch.stages || branch.stages.length === 0">
        No stages yet.
      </div>
    </div>
  </app-tabbed-page-tab>

  <!-- Stats & Charts TAB -->
  <app-tabbed-page-tab label="Stats & Charts">
      <app-branch-stats [branchId]="branchId"></app-branch-stats>
  </app-tabbed-page-tab>

  <!-- Sequences TAB -->
  <app-tabbed-page-tab label="Sequences">
      <app-sequences-panel [branchId]="branchId"></app-sequences-panel>
  </app-tabbed-page-tab>

<!--
  <p-tabPanel header="Configs">
  </p-tabPanel>
-->

  <!-- Status Badge TAB -->
  <app-tabbed-page-tab label="Status Badge & CCTray">
      <h3>Badges</h3>
      <div class="m-1">
          <input type="text" pInputText [value]="getBadgeUrl(null)" [size]="getBadgeUrl('issues').length" style="font-family: monospace;" #badgeUrlEl>
          <button type="button" pButton icon="pi pi-copy" class="p-button-outlined p-button-sm ml-5 mr-6" (click)="copyBadgeUrl(badgeUrlEl)"></button>
          <img [src]="getBadgeUrl(null)">
      </div>

      <div class="m-1">
          <input type="text" pInputText [value]="getBadgeUrl('tests')" [size]="getBadgeUrl('issues').length" style="font-family: monospace;" #badgeUrlTestsEl>
          <button type="button" pButton icon="pi pi-copy" class="p-button-outlined p-button-sm ml-5 mr-6" (click)="copyBadgeUrl(badgeUrlTestsEl)"></button>
          <img [src]="getBadgeUrl('tests')">
      </div>

      <div class="m-1">
          <input type="text" pInputText [value]="getBadgeUrl('issues')" [size]="getBadgeUrl('issues').length" style="font-family: monospace;" #badgeUrlIssuesEl>
          <button type="button" pButton icon="pi pi-copy" class="p-button-outlined p-button-sm ml-5 mr-6" (click)="copyBadgeUrl(badgeUrlIssuesEl)"></button>
          <img [src]="getBadgeUrl('issues')">
      </div>

      <h3>CCTray</h3>
      <div class="m-1">
          <input type="text" pInputText [value]="getBadgeUrl('cctray')" [size]="getBadgeUrl('issues').length" style="font-family: monospace;" #badgeUrlCCTrayEl>
          <button type="button" pButton icon="pi pi-copy" class="p-button-outlined p-button-sm ml-5 mr-6" (click)="copyBadgeUrl(badgeUrlCCTrayEl)"></button>
      </div>
  </app-tabbed-page-tab>

  <!-- Retention Policy -->
  <app-tabbed-page-tab label="Retention Policy">
      <form [formGroup]="retentionPolicyForm" class="mb-3 p-component">
          <h3>Logs in Clickhouse</h3>
          <div class="field grid">
              <label for="ci_logs" class="col-fixed w-12rem">CI Logs TTL in Months</label>
              <i class="pi pi-question-circle mr-2 text-400 hover:text-600 transition-colors transition-duration-200 cursor-pointer"
                 pTooltip="Defines the number of months for how long CI related logs of this branch (from Kraken Server and all Agents) will be kept in Clickhouse."></i>
              <div class="col">
                  <p-inputNumber inputId="ci_logs" formControlName="ci_logs" placeholder="ex: 6" mode="decimal" [min]="1" [max]="20">
                  </p-inputNumber>
              </div>
          </div>
          <div class="field grid">
              <label for="dev_logs" class="col-fixed w-12rem">Dev Logs TTL in Months</label>
              <i class="pi pi-question-circle mr-2 text-400 hover:text-600 transition-colors transition-duration-200 cursor-pointer"
                 pTooltip="Defines the number of months for how long Dev related logs of this branch (from Kraken Server and all Agents) will be kept in Clickhouse."></i>
              <div class="col">
                  <p-inputNumber inputId="dev_logs" formControlName="dev_logs" placeholder="ex: 3" mode="decimal" [min]="1" [max]="20">
                  </p-inputNumber>
              </div>
          </div>
          <!--
              <h3>Artifacts in MinIO/S3</h3>
              <div class="mx-2 my-1">
                  <span class="inline-block w-2rem">CI:</span> <input type="text" pInputText>
              </div>
              <div class="mx-2 my-1">
                  <span class="inline-block w-2rem">Dev:</span> <input type="text" pInputText>
              </div>
              -->
      </form>
      <button type="button" pButton icon="pi pi-check" label="Save" (click)="saveRetentionPolicy()"></button>
  </app-tabbed-page-tab>

  <!-- Data -->
  <app-tabbed-page-tab label="Data">
      <h3>User Data</h3>
      <app-user-data-panel
          [branchId]="branchId"></app-user-data-panel>
        <h3>Branch Data</h3>
        <pre>{{ branchData }}</pre>
  </app-tabbed-page-tab>

  <!-- Data -->
  <app-tabbed-page-tab label="Env Vars">
      <h3>Environment Variables</h3>
      <div class="flex">
          <div class="mr-5">

              <p-table [value]="envVars">
                  <ng-template pTemplate="header">
                      <tr>
                          <th class="w-20rem">Name</th>
                          <th class="w-30rem">Value</th>
                          <th class="w-1rem"></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-e>
                      <tr>
                          <td>{{ e.name }}</td>
                          <td>{{ e.value }}</td>
                          <td>
                              <button type="button" pButton icon="pi pi-trash" (click)="deleteEnvVar(e.name)" class="p-button-text p-button-danger"></button>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>

          </div>
          <div class="p-component" style="width: 50%;">
              <div class="field">
                  <label for="varName">Name</label>
                  <input id="varName" pInputText [(ngModel)]="varName" type="text" class="w-full">
              </div>
              <div class="field">
                  <label for="varValue">Value</label>
                  <input id="varValue" pInputText [(ngModel)]="varValue" type="text" class="w-full">
              </div>
              <button type="button" pButton icon="pi pi-plus" label="Add Variable" (click)="addEnvVar()"></button>
          </div>
      </div>
  </app-tabbed-page-tab>

  <!-- Logs -->
  <app-tabbed-page-tab label="Logs">
      <app-simple-logs-panel
          [visible]="logsPanelVisible"
          [branchId]="branchId"
          [topOffset]="300"
          [initServices]="['server', 'rq', 'scheduler', 'watchdog']"></app-simple-logs-panel>
  </app-tabbed-page-tab>

</app-tabbed-page>
