<p-menu #crumbMenu [popup]="true" [model]="crumbMenuItems"></p-menu>
<div class="breadcrumb">
    <div class="breadcrumb-items-list">
        <span *ngFor="let breadcrumb of breadcrumbs | async; last as isLast;"
              class="breadcrumb-item"
              [ngClass]="{'active': isLast}" aria-current="page">
            <div style="font-size: 1.3em; margin-right: 7px; cursor: pointer;" (click)="showCrumbMenu($event, crumbMenu, breadcrumb)">{{breadcrumb.label}}</div>

            <div *ngIf="breadcrumb.id" class="pi pi-chevron-right" style="font-size: 1em; color: #888;"></div>

            <a *ngIf="breadcrumb.id" [routerLink]="[breadcrumb.url]"  routerLinkActive="active" style="display: block; padding: 0 10px;">
                {{ breadcrumb.id }}
            </a>
            <div *ngIf="!isLast" class="pi pi-chevron-right" style="font-size: 0.9rem; color: #888;"></div>
        </span>
    </div>

    <ng-container *ngIf="page === 'Flows'">
        <div *ngIf="!prevFlowId">
            no prev
        </div>
        <div *ngIf="prevFlowId">
            <a [routerLink]="['/flows/' + prevFlowId]"  routerLinkActive="active" style="display: block;">
                << {{ prevFlowLabel }}
                   </a>
        </div>

        <div class='ml-3 mr-3'>
            |
        </div>

        <div *ngIf="!nextFlowId">
            no next
        </div>
        <div *ngIf="nextFlowId">
            <a [routerLink]="['/flows/' + nextFlowId]"  routerLinkActive="active" style="display: block;">
                {{ nextFlowLabel }} >>
            </a>
        </div>
    </ng-container>
</div>
