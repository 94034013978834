<p-menu #runBoxMenu [popup]="true" [model]="runBoxMenuItems"></p-menu>
<div [ngClass]="selected ? 'round-shadow-selected' : 'round-shadow'" [ngStyle]="{'background': bgColor, 'height': '100%', 'cursor': selectionEnabled ? 'e-resize' : ''}" (click)="onBoxClick()">
  <!-- title bar -->
  <div style="display: flex;">
    <b *ngIf="run" style="font-size: 1.1rem; flex: 1;"><a routerLink="/runs/{{run.id}}/jobs" routerLinkActive="active" style="color: unset;">{{ run.stage_name }} {{ run.label ? ' - ' + run.label : '' }}</a></b>
    <b *ngIf="!run" style="font-size: 1.1rem; flex: 1; color: #aaa;">{{ stage.name }}</b>
    <div *ngIf="run" style="font-size: 0.7rem; margin-right: 10px; display: flex; flex-direction: column; align-items: end;">
      <div>{{(run.started || run.created) | localtime}}</div>
      <div *ngIf="run.state !== 'manual'">{{run.duration}}
        <span *ngIf="!run.started">waiting to start </span>
        <span *ngIf="run.state === 'in-progress'">
          <i class="pi pi-spin pi-spinner" style="font-size: 16px; vertical-align: text-top;"></i>
        </span>
      </div>
      <div *ngIf="run.state === 'manual'">waiting for manual trigger
      </div>
    </div>
    <span style="float:right; cursor: pointer; padding-right: 8px;" aria-hidden="true" class="pi pi-bars" (click)="showRunMenu($event, runBoxMenu, run)"></span>
  </div>
  <!--- details -->
  <div *ngIf="run" class="job-detal-items">

    <div *ngIf="run.jobs_total">
      <div routerLink="/runs/{{ run.id }}/jobs" style="cursor: pointer; display: inline;">
      <b style="font-weight: 500;">Execution</b><br>
      {{run.jobs_total}} jobs
      <span *ngIf="run.jobs_error">
        <br>
        {{run.jobs_error}} errs <i class="pi pi-times-circle" style="color: var(--redish2); vertical-align: sub;"></i>
      </span>
      <span *ngIf="run.jobs_rerun">
        <br>
        {{run.jobs_rerun}} reruns
      </span>
      <span *ngIf="run.jobs_waiting">
        <br>
        {{run.jobs_waiting}} waiting <i class="pi pi-spin pi-spinner" style="font-size: 16px; vertical-align: bottom;"></i>
      </span>
      <span *ngIf="run.jobs_executing">
        <br>
        {{run.jobs_executing}} executing <i class="pi pi-spin pi-spinner" style="font-size: 16px; vertical-align: bottom;"></i>
      </span>
      <span *ngIf="run.jobs_processing">
        <br>
        {{run.jobs_processing}} processing <i class="pi pi-spin pi-spinner" style="font-size: 16px; vertical-align: bottom;"></i>
      </span>
      </div>
    </div>

    <div *ngIf="run.tests_total" routerLink="/runs/{{ run.id }}/results" style="cursor: pointer; display: flex;">
      <div>
        <b>Tests</b><br>
        {{run.tests_passed * 100 / run.tests_total | number:'1.1-1'}}% passed
        <i *ngIf="run.tests_passed !== run.tests_total" class="pi pi-exclamation-triangle" style="color: var(--orangish2); font-size: 1.3em; vertical-align: bottom;"></i>
        <i *ngIf="run.tests_passed === run.tests_total" class="pi pi-thumbs-up" style="color: var(--greenish2); font-size: 1.3em; vertical-align: bottom;"></i>
        <br>
        {{run.tests_passed}}/{{run.tests_total}}
        <span *ngIf="run.tests_not_run">
          <br>{{run.tests_not_run}} not run
        </span>
      </div>
      <div style="margin-left: 15px;" *ngIf="run.regr_cnt > 0 || run.fix_cnt > 0">
        <br>
        <span *ngIf="run.regr_cnt > 0">{{ run.regr_cnt }} regressions <i class="pi pi-exclamation-triangle" style="color: var(--redish2); font-size: 1.3em; vertical-align: bottom;"></i><br></span>
        <span *ngIf="run.fix_cnt > 0">{{ run.fix_cnt }} fixes <i class="pi pi-thumbs-up" style="color: var(--greenish2); font-size: 1.3em; vertical-align: bottom;"></i></span>
      </div>
    </div>

    <div *ngIf="run.issues_total" routerLink="/runs/{{ run.id }}/issues" style="cursor: pointer;">
      <b>Issues</b><br>
      {{run.issues_total}} total <i class="pi pi-exclamation-triangle" style="color: var(--orangish2); font-size: 1.3em; vertical-align: bottom;"></i><br>
      <span *ngIf="run.issues_new > 0">{{run.issues_new}} new <i class="pi pi-exclamation-triangle" style="color: var(--redish2); font-size: 1.3em; vertical-align: bottom;"></i></span>
    </div>

  </div>

  <div *ngIf="!run">
    not run
  </div>
</div>
