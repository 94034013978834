<p-tabMenu [model]="tabs">
    <ng-template pTemplate="item" let-item let-i="index">
        <div style="display: flex; justify-content: space-between;">
            <div class="p-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon" style="font-size: 2em"></div>
            <div class="p-menuitem-text">
                <b>{{ item.label }}</b>
            </div>
            &nbsp;
            <div
                class="p-menuitem-icon pi pi-times"
                style="font-size: 1rem"
                (click)="closeTab($event, i)"
                *ngIf="i !== 0"
            ></div>
        </div>
    </ng-template>
</p-tabMenu>


<!-- Agents tab -->
<div *ngIf="activeTabIdx === 0">
  <div style="display: flex; margin: 15px 5px;">

    <p-button label="Refresh" icon="pi pi-refresh" (onClick)="refreshAgents(agentsTable)" [style]="{'margin': '0 20px 0 60px'}"></p-button>

  </div>

  <p-menu #agentMenu [popup]="true" [model]="agentMenuItems"></p-menu>
  <p-table #agentsTable
           id="agents"
           [value]="agents"
           [lazy]="true"
           selectionMode="single"
           [(selection)]="agent"
           (onLazyLoad)="loadAgentsLazy($event)"
           [paginator]="true"
           [rows]="30"
           [totalRecords]="totalAgents"
           [loading]="loadingAgents"
           [rowsPerPageOptions]="[10,20,30,50,100]"
           [showCurrentPageReport]="true">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 60px;">ID</th>
        <th style="width: 120px;">Address</th>
        <th style="width: 160px;">Name</th>
        <th style="width: 120px;">IP Address</th>
        <!-- <th style="width: 60px;">State</th> -->
        <th style="width: 80px;">Enabled</th>
        <th style="width: 120px;">Last Seen</th>
        <th style="width: 10%;">Groups</th>
        <th style="width: 10%;">System</th>
        <th style="width: 10%;">Isolation</th>
        <th style="width: 10%;">Host Info</th>
        <th style="width: 10%;">User Attrs</th>
        <th style="width: 80px;">Job</th>
        <th style="width: 4rem;">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-agent>
      <tr [pSelectableRow]="agent">
        <td>{{agent.id}}</td>
        <td>{{agent.address}}</td>
        <td><a routerLink="/agents/{{agent.id}}">{{agent.name}}</a></td>
        <td>{{agent.ip_address}}</td>
        <!-- <td>{{ agent.state }}</td> -->
        <td>
          <p-inputSwitch [ngModel]="!agent.disabled" (onChange)="changeAgentDisable($event, agent)"
                         [disabled]="!auth.hasPermission(null, 'admin')"
                         [pTooltip]="auth.permTip(null, 'admin')"></p-inputSwitch>
        </td>
        <td>{{ agent.last_seen ? (agent.last_seen | localtime) : '' }}</td>
        <td>
          <span *ngFor="let g of agent.groups" style="padding-right: 5px;">
            <a routerLink="/agents-groups/{{ g.id }}">{{ g.name }}</a>
          </span>
        </td>
        <td>
          <span *ngIf="agent.host_info">
          {{ agent.host_info['system_type'] }} /
          {{ agent.host_info['system'] }}
          </span>
        </td>
        <td>
          <span *ngIf="agent.host_info">
          {{ agent.host_info['isolation_type'] }}
          {{ agent.host_info['isolation'] === 'bare-metal' ? '' : ' / ' + agent.host_info['isolation'] }}
          </span>
        </td>
        <td>
          <div *ngIf="agent.host_info">
            <div *ngFor="let hi of filterHostInfo(agent.host_info)">
              <span style="color: #888;">{{ hi.key }}</span>: {{ hi.value }}
            </div>
          </div>
        </td>
        <td>{{ agent.user_attrs }}</td>
        <td><a *ngIf="agent.job" routerLink="/runs/{{ agent.job.run_id }}/jobs">{{ agent.job.id }}</a></td>
        <td>
          <button
            type="button"
            pButton
            icon="pi pi-bars"
            (click)="showAgentMenu($event, agentMenu, agent)"
            ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Single agent tab -->
<div *ngIf="activeTabIdx !== 0" class="p-component">
    <p-tabView (onChange)="handleAgentTabChange($event)">
        <p-tabPanel header="Overview">
            <div class="grid">
                <div class="col-4">
                    <h3>General</h3>
                    <table>
                        <tr>
                            <th style="width: 8rem;"></th>
                            <th style="width: 14rem;"></th>
                        </tr>

                        <tr>
                            <td style="text-align: right; padding-right: 10px;">
                                Agent Name
                            </td>
                            <td>
                                {{ agentTab.agent.name }}
                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: right; padding-right: 10px;">
                                Address
                            </td>
                            <td>
                                {{ agentTab.agent.address }}
                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: right; padding-right: 10px;">
                                IP Address
                            </td>
                            <td>
                                {{ agentTab.agent.ip_address }}
                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: right; padding-right: 10px;">
                                State
                            </td>
                            <td>
                                {{ agentTab.agent.state }}
                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: right; padding-right: 10px;">
                                Enabled
                            </td>
                            <td>
                                {{ !agentTab.agent.disabled }}
                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: right; padding-right: 10px;">
                                Created
                            </td>
                            <td>
                                {{ agentTab.agent.created | localtime }}
                            </td>
                        </tr>

                        <tr *ngIf="agentTab.agent.deleted">
                            <td style="text-align: right; padding-right: 10px;">
                                Deleted
                            </td>
                            <td>
                                {{ agentTab.agent.deleted | localtime }}
                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: right; padding-right: 10px;">
                                Groups
                            </td>
                            <td>
                                <p-multiSelect [options]="agentGroups" optionLabel="name" [(ngModel)]="agentTab.agent.groups" [panelStyle]="{minWidth:'12em'}"></p-multiSelect>
                            </td>
                        </tr>
                    </table>
                    <p-button label="Save" icon="pi pi-check" (onClick)="saveAgent(agentsTable)"
                              [disabled]="!auth.hasPermission(null, 'admin')"
                              [pTooltip]="auth.permTip(null, 'admin')"></p-button>
                </div>

                <div class="col-4">
                    <h3>Host Info</h3>
                    <table>
                        <tr>
                            <th style="width: 8rem;"></th>
                            <th style="width: 12rem;"></th>
                        </tr>

                        <tr *ngFor="let hi of agentTab.agent.host_info | keyvalue">
                            <td style="text-align: right; padding-right: 10px;">
                                {{ hi.key }}
                            </td>
                            <td>
                                {{ hi.value }}
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="agentTab.agent.extra_attrs" class="col-4">
                    <h3>Other</h3>
                    <table>
                        <tr>
                            <th style="width: 8rem;"></th>
                            <th style="width: 12rem;"></th>
                        </tr>

                        <tr *ngFor="let ea of agentTab.agent.extra_attrs | keyvalue">
                            <td style="text-align: right; padding-right: 10px;">
                                {{ ea.key }}
                            </td>
                            <td>
                                {{ ea.value }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </p-tabPanel>

        <p-tabPanel header="Jobs">
            <p-table #agentJobsTable
                     id="agentJobs"
                     [value]="agentJobs"
                     [lazy]="true"
                     (onLazyLoad)="loadAgentJobsLazy($event)"
                     [paginator]="true"
                     [rows]="30"
                     [totalRecords]="totalAgentJobs"
                     [loading]="loadingAgentJobs"
                     [rowsPerPageOptions]="[10,20,30,50,100]"
                     [showCurrentPageReport]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 60px;">ID</th>
                        <th style="width: 120px;">Name</th>
                        <th style="width: 160px;">Started</th>
                        <th style="width: 120px;">Finished</th>
                        <th style="width: 120px;">Completed</th>
                        <th style="width: 120px;">Run ID</th>
                        <th style="width: 120px;">State</th>
                        <th style="width: 120px;">Covered</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-job>
                    <tr>
                        <td>{{job.id}}</td>
                        <td>{{job.name}}</td>
                        <td>{{job.started | localtime}}</td>
                        <td>{{job.finished | localtime}}</td>
                        <td>{{job.completed | localtime}}</td>
                        <td>{{job.run_id}}</td>
                        <td>{{job.state}}</td>
                        <td>{{job.covered}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>

        <p-tabPanel header="Logs">
            <app-simple-logs-panel
                [visible]="logsPanelVisible"
                [agentId]="agentTab.agent.id"
                [topOffset]="300"></app-simple-logs-panel>
        </p-tabPanel>
    </p-tabView>

</div>
