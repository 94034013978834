<!-- change password box -->
<p-dialog header="Change Password for {{ user?.name }}" [(visible)]="show" (onHide)="onHide()">
  <div class="p-fluid">
    <div class="field">
      <label for="password-old">Old Password</label>
      <input id="password-old" type="password" pPassword [(ngModel)]="passwordOld" [feedback]="false"/>
    </div>
    <div class="field">
      <label for="password-new1">New Password</label>
      <input id="password-new1" type="password" pPassword [(ngModel)]="passwordNew1" [feedback]="true"/>
    </div>
    <div class="field">
      <label for="password-new2">Repeat New Password</label>
      <input id="password-new2" type="password" pPassword [(ngModel)]="passwordNew2" [feedback]="false"
             (keyup)="passwdChangeKeyUp($event.key)"/>
    </div>
  </div>

  <p-footer>
    <p-button icon="pi pi-times" (click)="cancel()" label="Cancel" styleClass="p-button-outlined p-button-secondary"></p-button>
    <p-button icon="pi pi-check" (click)="changePassword()" label="Change"></p-button>
  </p-footer>
</p-dialog>
