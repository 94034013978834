<!-- New Project dialog -->
<p-dialog header="New Project" [(visible)]="newProjectDlgVisible">
  <input type="text" pInputText [(ngModel)]="projectName" (keydown)="newProjectKeyDown($event)" autofocus/>
  <p-footer>
    <button type="button" (click)="cancelNewProject()" pButton icon="pi pi-times" label="Cancel" class="p-button-outlined p-button-secondary"></button>
    <button type="button" (click)="addNewProject()" pButton icon="pi pi-check" label="Add"></button>
  </p-footer>
</p-dialog>

<!-- New Branch dialog -->
<p-dialog header="New Branch in {{selectedProject.name}}" [(visible)]="newBranchDlgVisible">
  <input type="text" pInputText [(ngModel)]="branchName" (keydown)="newBranchKeyDown($event)"/>
  <p-footer>
    <button type="button" (click)="cancelNewBranch()" pButton icon="pi pi-times" label="Cancel" class="p-button-outlined p-button-secondary"></button>
    <button type="button" (click)="addNewBranch()" pButton icon="pi pi-check" label="Add"></button>
  </p-footer>
</p-dialog>


<!-- Page header -->
<div style="margin-bottom: 10px;">
  <p-button label="New Project" icon="pi pi-plus" (onClick)="newProject()"
            [disabled]="!auth.hasPermission(null, 'admin')"
            [pTooltip]="auth.permTip(null, 'admin')"></p-button>
</div>

<!-- Grid with all projects -->
<div class="grid">
  <div class="col-6" *ngFor="let proj of projects">
    <div class="round-shadow" style="height: 100%;">
      <div style="display: flex; justify-content: space-between;">
        <div style="font-size: 1.7em; margin-top: 2px;">
          <a routerLink="/projects/{{ proj.id }}" style="color: var(--kk-color);">Project <b>{{proj.name}}</b></a>
        </div>
        <div style="margin-top: 10px;">
          <a pButton icon="pi pi-cog" routerLink="/projects/{{ proj.id }}" class="p-button-outlined p-button-secondary p-button-sm" style="margin-right: 6px;"></a>
          <p-button label="New Branch" icon="pi pi-plus" (onClick)="newBranch(proj)" styleClass="p-button-outlined p-button-secondary p-button-sm"
            [disabled]="!auth.hasPermission(proj.id, 'pwrusr')"
            [pTooltip]="auth.permTip(proj.id, 'pwrusr')"></p-button>
        </div>
      </div>

      <div class="grid" style="margin: 6px 0 0 12px;">
        <div *ngFor="let branch of proj.branches" style="padding: 0px 0 0 0px;" class="col-4">
          <div style="margin: 0 0 10px 0; display: flex;">
            <div style="font-weight: bold; font-size: 2.0rem; margin-right: 10px; color: #888;">
              <i class="fa fa-code-branch"></i>
            </div>
            <div>
              <div style="font-weight: bold; font-size: 1.5em;border-bottom: 1px #ccc solid; margin-bottom: 6px;">
                <a routerLink="/branches/{{ branch.id }}/ci">
                  {{ branch.name }}
                </a>
              </div>
              <div *ngIf="branch.last_completed_flow">
                <a routerLink="/flows/{{ branch.last_completed_flow.id }}" style="padding-right: 1em;">
                  {{ branch.last_completed_flow.label || branch.last_completed_flow.id }}
                  <i *ngIf="!branch.last_completed_flow.errors" class="pi pi-thumbs-up" style="color: var(--greenish2);"></i>
                  <i *ngIf="branch.last_completed_flow.errors" class="pi pi-times-circle" style="color: var(--redish2);"></i>
                </a>
                <span style="color: #bbb;">
                  {{ branch.last_completed_flow.finished | localtime:'ago' }}
                </span>
              </div>
              <div *ngIf="branch.last_incomplete_flow">
                <a routerLink="/flows/{{ branch.last_incomplete_flow.id }}" style="padding-right: 1em;">
                  {{ branch.last_incomplete_flow.label || branch.last_incomplete_flow.id }}
                  <i *ngIf="branch.last_incomplete_flow.errors" class="pi pi-times-circle" style="color: red; padding-right: 0.2em;"></i>
                  <i class="pi pi-spin pi-spinner" style="color: grey;"></i>
                </a>
                <span style="color: #bbb;">
                  {{ branch.last_incomplete_flow.created | localtime:'ago' }}
                </span>
              </div>
              <div *ngIf="!branch.last_completed_flow && !branch.last_incomplete_flow" style="color: #ccc;">
                no flows yet
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
