<div *ngIf="job && job.steps && stepsStates.length > 0"
     #logPanel
     (scroll)="scrolled($event)"
     style="background-color: black; color: #ccc; height: calc(100vh - 300px); overflow-y: auto;">
    <div *ngFor="let ss of stepsStates; let idx = index" #logStep>
        <ng-container *ngFor="let step of [stepsStates[idx].step]">
        <!-- step heading -->
        <div style="margin-bottom: 1px;"
             [ngClass]="getStepHeadingClass(step)"
             (click)="toggleStep(idx)">
            <div class="flex align-items-baseline">
                <i class="pi" [ngClass]="stepsStates[idx].loading ? 'pi-spin pi-spinner' : (stepsStates[idx].expanded ? 'pi-angle-down' : 'pi-angle-right')"></i>
                <i class="ml-2 mr-3 text-sm"  [ngClass]="getStepStatusClass(step)"></i>
                <div>
                    {{ idx + 1 }}.
                    {{ step.name }}
                </div>
            </div>
            <div *ngIf="ss.expanded"
                 class="px-1 ml-auto mr-0 my-0 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                        transition-colors transition-duration-200 hover:bg-gray-600 font-normal"
                 [ngClass]="logTimestamps ? 'font-bold' : ''"
                 (click)="toggleTimestamps(idx)">timestamps: {{ logTimestamps ? 'on' : 'off' }}</div>
        </div>
        <!-- expanded step content -->
        <ng-container *ngIf="ss.expanded">
            <!-- step status bar -->
            <div class="mx-2 p-2 bg-gray-900">
                <div>
                    <span class="mr-4">
                        <span class="text-600">Status:</span> {{ getStepStatus(step) }}
                    </span>
                    <ng-container *ngIf="step.result">
                        <span *ngIf="step.result.duration" class="mr-4">
                            <span class="text-600">Duration:</span> {{ step.result.duration }}
                        </span>
                        <ng-container *ngIf="step.result['reason']">
                            <span class="mr-4">
                                <span class="text-600">Reason:</span> {{ step.result['reason'] }}
                            </span>
                            <span *ngIf="step.result['reason'] === 'step-timeout'">
                                <span class="text-600">Timeout:</span> {{ step.timeout || '60' }}s
                            </span>
                            <span *ngIf="step.result['reason'] !== 'step-timeout'">
                                <span class="text-600">Message:</span> <pre class="text-yellow-300 my-1">{{ step.result['msg'] }}</pre>
                            </span>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="flex flex-wrap">
                    <div class="mr-4 white-space-nowrap">
                        <span class="text-600">Tool:</span> {{ step.tool }}<span class="text-500" style="font-size: 80%; vertical-align: text-top;">@</span>{{ step.tool_version }}
                    </div>
                    <div *ngFor="let it of getStepInfo(step) | keyvalue" class="mr-4 white-space-nowrap">
                        <span class="text-600">{{ it.key }}:</span> {{ it.value }}
                    </div>
                </div>
            </div>
            <!-- step control buttons bar -->
            <div class="mx-5 flex align-items-baseline">
                <div class="p-2 m-1">
                    {{ stepsStates[idx].start }}..{{ stepsStates[idx].end }} of {{ stepsStates[idx].total }} lines.
                </div>
                <div *ngIf="stepsStates[idx].start > 1"
                     class="py-1 px-2 m-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                            transition-colors transition-duration-200 hover:bg-gray-800"
                     (click)="loadPrevPage(idx)">load previous page</div>
                <div *ngIf="stepsStates[idx].start > 1"
                     class="py-1 px-2 m-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                            transition-colors transition-duration-200 hover:bg-gray-800"
                     (click)="loadFirstPage(idx)">load the first page</div>
                <div *ngIf="stepsStates[idx].total < 10000 && stepsStates[idx].logs.length < stepsStates[idx].total"
                     class="py-1 px-2 m-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                            transition-colors transition-duration-200 hover:bg-gray-800"
                     (click)="loadAllPages(idx)">load all</div>
                <div class="p-2 m-1">
                    <i class="pi pi-download mr-1"></i>
                    <a [href]="'/bk/job_log/' + prvJob.id + '/' + idx" pTooltip="Download step logs"
                       class="text-100">download log</a>
                </div>
            </div>
            <!-- step logs -->
            <div *ngIf="stepsStates[idx].logs && stepsStates[idx].logs.length > 0"
                 class="py-1 relative">

                <div class="px-2 mr-3 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                            transition-colors transition-duration-200 hover:bg-gray-600 font-normal
                            absolute right-0 top-0"
                     (click)="scrollToStepBottom($event, idx)"><i class="pi pi-arrow-down text-sm"></i> bottom</div>

                <div style="font-size: 12px; font-family: Menlo, DejaVu Sans Mono, Liberation Mono, Consolas, Ubuntu Mono, Courier New, andale mono, lucida console, monospace;">
                    <div *ngFor="let l of stepsStates[idx].logs; let l_idx = index"
                         class="flex">
                        <div style="width: 3rem; color: #888; text-align: right; margin-right: 0.5rem; flex: none;">{{ stepsStates[idx].start + l_idx  }}</div>
                        <div style="white-space: pre-wrap; overflow-x: auto; word-break: break-word; margin: 0;"
                             [innerHTML]="prepareLogLine(stepsStates[idx], l) | noSanitize">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="stepsStates[idx].end < stepsStates[idx].total" class="mb-1 flex">
                <div style="width: 3rem; margin-right: 0.5rem;">
                </div>
                <div>
                    ...
                </div>
            </div>
            <!-- step control buttons bar 2 -->
            <div class="mx-5 my-1 flex align-items-baseline">
                <div class="p-2 m-1" *ngIf="stepsStates[idx].logs.length > 30">
                    {{ stepsStates[idx].start }}..{{ stepsStates[idx].end }} of {{ stepsStates[idx].total }} lines.
                </div>
                <div *ngIf="stepsStates[idx].end < stepsStates[idx].total"
                     class="py-1 px-2 m-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                            transition-colors transition-duration-200 hover:bg-gray-800"
                     (click)="loadNextPage(idx, false)">load next page</div>
                <div *ngIf="stepsStates[idx].end < stepsStates[idx].total"
                     class="py-1 px-2 m-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                            transition-colors transition-duration-200 hover:bg-gray-800"
                     (click)="loadLastPage(idx)">load the last page</div>
                <div *ngIf="stepsStates[idx].total < 10000 && stepsStates[idx].logs.length < stepsStates[idx].total"
                     class="py-1 px-2 m-1 border-round-lg border-solid border-1 border-800 bg-gray-900 cursor-pointer
                            transition-colors transition-duration-200 hover:bg-gray-800"
                     (click)="loadAllPages(idx)">load all</div>
            </div>
        </ng-container>
        </ng-container>
    </div>
</div>
