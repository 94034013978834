<div style="display: flex; justify-content: flex-end; margin: -5px 20px 5px 0;">

  <p-progressSpinner *ngIf="loadingLogs" [style]="{ width: '30px', height: '30px', 'margin': '3px 20px 0 0' }"></p-progressSpinner>

  <p-toggleButton onIcon="pi pi-volume-up" offIcon="pi pi-volume-down" pTooltip="Switch on/off internal logs" [style]="{'margin-right': '7px', 'height': '39px'}" [(ngModel)]="logInternals"></p-toggleButton>
  <p-toggleButton onIcon="pi pi-clock" offIcon="pi pi-clock" pTooltip="Switch on/off timestamps" [style]="{'margin-right': '7px', 'height': '39px'}" [(ngModel)]="logTimestamps" (onChange)="handleTsChange()"></p-toggleButton>
  <a pButton icon="pi pi-download" [style]="{'margin-right': '7px', 'color': 'var(--kk-bg-color)'}" [href]="'/bk/job_log/' + prvJobId" pTooltip="Download logs"></a>
  <p-button icon="pi pi-plus" [style]="{'margin-right': '7px'}" (click)="logZoomIn()" pTooltip="Bigger fonts"></p-button>
  <p-button icon="pi pi-minus" [style]="{'margin-right': '7px'}" (click)="logZoomOut()" pTooltip="Smaller fonts"></p-button>
  <p-button icon="pi pi-fast-backward" [style]="{'margin-right': '7px'}" (click)="loadFirstPage()" pTooltip="Load the first page"></p-button>
  <p-button icon="pi pi-backward" [style]="{'margin-right': '7px'}" (click)="loadPrevPage()" pTooltip="Load previous page"></p-button>
  <p-button icon="pi pi-arrow-up" [style]="{'margin-right': '7px'}" (click)="logScrollUp()" pTooltip="Scroll to the top"></p-button>
  <p-button icon="pi pi-arrow-down" [style]="{'margin-right': '7px'}" (click)="logScrollDown()" pTooltip="Scroll to the bottom"></p-button>
  <p-button icon="pi pi-forward" [style]="{'margin-right': '7px'}" (click)="loadNextPage()" pTooltip="Load next page"></p-button>
  <p-button icon="pi pi-fast-forward" (click)="loadEndPage()" pTooltip="Load the end page"></p-button>
</div>

<div style="background-color: black; color: #ccc; padding: 10px; height: calc(100vh - 340px); font-family: Menlo, DejaVu Sans Mono, Liberation Mono, Consolas, Ubuntu Mono, Courier New, andale mono, lucida console, monospace; font-size: 12px; overflow-y: auto;" #logBox  (scroll)="scrolled($event)">
  <div *ngFor="let frag of logFragments" #logFrag>
    <div *ngIf="frag.title" style="font-size: 1.2em; font-weight: bold; color: lawngreen; cursor: pointer;" (click)="frag.expanded = !frag.expanded">
      <i *ngIf="!frag.expanded" class="pi pi-caret-right" style="vertical-align: bottom;"></i>
      <i *ngIf="frag.expanded" class="pi pi-caret-down" style="vertical-align: bottom;"></i>
      {{ frag.step + 1 }}. {{frag.title }}
    </div>
    <div *ngIf="frag.expanded" [ngStyle]="{'font-size': '' + fontSize + 'em'}">
      <div *ngFor="let log of frag.logs" style="display: flex;">
        <div style="width: 3em; color: #888; text-align: right; margin-right: 0.5em; flex: none;">{{ log.idx }}</div>
        <div *ngIf="logInternals" style="width: 4em; color: #888; text-align: right; margin-right: 0.5em; flex: none;">{{ log.service }}</div>
        <div [ngClass]="['log-entry-base', log.cls]" [innerHTML]="log.message | noSanitize"></div>
      </div>
    </div>
  </div>
</div>
