
<p-toast></p-toast>

<div class="content" role="main" *ngIf="session">

  <!-- top bar with logo and menu -->
  <div class="toolbar" role="banner">
      <span>
      <a style="font-size: 2rem; cursor: pointer; color: white;" class="logo1" href="/">Kraken</a>&nbsp;
      <a style="font-size: 1rem; cursor: pointer; color: white;" class="logo1" href="https://github.com/Kraken-CI/kraken/releases/tag/v{{ krakenVersion }}" target="blank">{{ krakenVersion }}</a>
      </span>


    <p-menubar [model]="topMenuItems" styleClass="menubar" #topmenubar>
      <!-- <p-progressSpinner -->
      <!--     *ngIf="(loadingInProgress | async)['state']" -->
      <!--     [pTooltip]="(loadingInProgress | async)['text']" -->
      <!--     [style]="{ width: '30px', height: '30px', 'vertical-align': 'top', 'margin-right': '20px' }" -->
      <!--     strokeWidth="6" -->
      <!--     fill="#005B9F" -->
      <!--     animationDuration=".5s" -->
      <!-- ></p-progressSpinner> -->
      <!-- <input type="text" pInputText placeholder="Search" /> -->
      <p-splitButton
        label="Logout {{ session.user.name }}"
        (onClick)="logout()"
        icon="pi pi-sign-out"
        [model]="logoutMenuItems"
        [style]="{'margin-left': '20px'}"
      ></p-splitButton>
    </p-menubar>

  </div>

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <!-- breadcrumb bar -->
  <div style="display: flex; justify-content: space-between; align-items: center; background-color: var(--kk-bg-color1);">
    <app-breadcrumbs></app-breadcrumbs>
<!--
    <div style="margin-right: 16px;">
      <a routerLink="/agents" style="margin-left: 20px;"><i class="fa fa-server" style="vertical-align: text-bottom;"></i> Agents</a>
      <a routerLink="/discovered-agents" style="margin-left: 20px;"><i class="fa fa-server" style="vertical-align: text-bottom;"></i> Discovered Agents</a>
    </div>
-->
  </div>

  <div style="margin: 10px;">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- login screen -->
<div class="content" role="main" *ngIf="!session" style="background-color: #007ad9; color: white; min-height: 100vh; padding-bottom: 2em;">

  <div style="text-align: center; font-size: 10em; padding: 5rem 0 3rem 0;" class="logo1">
    Kraken
  </div>

  <!-- login box -->
  <div>
    <div class="p-fluid" style="width: 20em; margin: auto;">
      <div class="field">
        <label for="username">Username</label>
        <input id="username" type="username" pInputText [(ngModel)]="username" />
      </div>
      <div class="field">
        <label for="password">Password</label>
        <input id="password" type="password" pPassword [(ngModel)]="password" [feedback]="false"
               (keyup)="passwdKeyUp($event.key)"/>
      </div>
    </div>

    <div style="text-align: center;">
      <p-button icon="pi pi-sign-in" (click)="login()" label="Login"></p-button>
    </div>

    <div style="text-align: center; padding-top: 20px; font-size: 1.2em;" *ngIf="isDemo() || isLocal()">
      For demo enter: demo / demo.
    </div>
    <div style="text-align: center; padding-top: 8px; font-size: 1.2em;" *ngIf="isLocal()">
      For admin enter: admin / admin.
    </div>

    <div style="padding-top: 60px; display: flex; width: 60em; margin: auto; justify-content: center; gap: 2em;">
        <div *ngIf="settings.idp.google_enabled">
            <p-button icon="pi pi-google" (click)="loginWith('google')" label="Login with Google"></p-button>
        </div>
        <div *ngIf="settings.idp.microsoft_enabled">
            <p-button icon="pi pi-microsoft" (click)="loginWith('microsoft')" label="Login with Microsoft"></p-button>
        </div>
        <div *ngIf="settings.idp.github_enabled">
            <p-button icon="pi pi-github" (click)="loginWith('github')" label="Login with GitHub"></p-button>
        </div>
        <div *ngIf="settings.idp.auth0_enabled">
            <p-button icon="pi pi-sign-in" (click)="loginWith('auth0')" label="Login with Auth0"></p-button>
        </div>
    </div>
  </div>
</div>

<app-change-passwd-dlg [user]="auth.session?.user" [(show)]="displayPasswdBox"></app-change-passwd-dlg>
