<pre>{{ data }}</pre>

<ng-container *ngIf="dataCI">
    <h3>CI</h3>
    <pre>{{ dataCI }}</pre>
</ng-container>

<ng-container *ngIf="dataDev">
    <h3>Dev</h3>
    <pre>{{ dataDev }}</pre>
</ng-container>
