<p-tabMenu [model]="tabs" [activeItem]="activeTab">
  <ng-template pTemplate="item" let-item let-i="index">
    <span style="font-size: 1.1em; font-weight: bold; margin-right: 5px;">{{ item.label }}</span>
    <span *ngIf="recordsCount[i]" style="background-color: #9cbae6; border-radius: 15px; padding: 1px 8px 2px; color: white; font-weight: bold;">{{ recordsCount[i] }}</span>
  </ng-template>
</p-tabMenu>

<!-- JOBS TAB -->
<div *ngIf="activeTabIdx === 0">
  <div class="grid" style="margin-top: 0;">
    <div class="col-5">

      <div style="margin: 0 0 10px 0; display: flex; justify-content: space-between;">
        <div style="display: flex;">
          <p-button label="Refresh" icon="pi pi-refresh" (onClick)="refreshJobs(jobsTable)"></p-button>
          <p-button label="Rerun All" icon="pi pi-replay" (onClick)="rerunAll()" [style]="{'margin-left': '20px'}"
                    [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
                    [pTooltip]="auth.permTip(projectId, 'pwrusr')"></p-button>
          <div style="margin: auto 20px;">
            Run State: {{ run.state }}
          </div>
        </div>
        <div>
          <p-inputSwitch [(ngModel)]="includeCovered" [style]="{'vertical-align': 'middle'}" (onChange)="coveredChange(jobsTable)"></p-inputSwitch> Covered
        </div>
      </div>

      <p-table #jobsTable
               id="jobs"
               [value]="jobs"
               [lazy]="true"
               selectionMode="single"
               [(selection)]="job"
               (onLazyLoad)="loadJobsLazy($event)"
               [paginator]="true"
               [rows]="30"
               [totalRecords]="totalJobs"
               (onRowSelect)="jobSelected($event)"
               [rowsPerPageOptions]="[10,20,30,50,100]"
               [showCurrentPageReport]="true">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 120px;">Job Name</th>
            <th style="width: 100px;">State</th>
            <th style="width: 100px;">Completion Status</th>
            <th style="width: 20%;">System</th>
<!--            <th style="width: 10%;">Config</th>-->
            <th style="width: 10%; overflow-x: hidden; text-overflow: ellipsis;">Agent Group</th>
            <th style="width: 20%; overflow-x: hidden; text-overflow: ellipsis;">Agent</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-job>
          <tr [pSelectableRow]="job" [ngStyle]="{'background-color': job.covered ? '#aa9' : ''}">
            <td>{{job.name}}</td>
            <td>{{getJobState(job)}} <i *ngIf="job.state !== 5" class="pi pi-spin pi-spinner" style="font-size: 16px; vertical-align: text-top;"></i></td>
            <td>
              <i style="vertical-align: bottom; font-size: 1.4em;" [ngClass]="getJobStatusClass(job)"></i> {{getJobStatus(job)}}
            </td>
            <td style="overflow-wrap: anywhere;">{{ job.system }}</td>
<!--            <td>default</td>-->
            <td><a routerLink="/agents-groups/{{ job.agents_group_id }}">{{ job.agents_group_name }}</a></td>
            <td><a routerLink="/agents/{{ job.agent_id }}">{{ job.agent_name }}</a></td>
          </tr>
        </ng-template>
      </p-table>

    </div>

    <div class="col-7">
      <div *ngIf="job">
        <div style="display: flex; justify-content: space-between;">
          <div style="margin: 10px 0;">
            <span style="font-size: 1.2rem; font-weight: bold; margin-right: 20px;">{{job.id}}. {{ job.name }}</span>
            <span>Started: {{ job.started ? (job.started | localtime) : 'not yet' }}</span>
            <span style="margin-left: 20px;">Completed: {{ job.completed ? (job.completed | localtime) : 'not yet' }}</span>
            <span *ngIf="job.duration" style="margin-left: 20px;">Duration: {{ job.duration }}</span>
            <span *ngIf="job.notes" style="margin-left: 30px;">
              <p-message severity="warn" text="Notes: {{ job.notes }}"></p-message>
            </span>
          </div>
          <div style="margin: auto 0;">
            <button *ngIf="job.state !== 5" type="button" pButton label="Cancel Job" icon="pi pi-times" (click)="cancelJob()"
                    class="p-button-danger p-button-outlined"
                    [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
                    [pTooltip]="auth.permTip(projectId, 'pwrusr')"></button>
            <button *ngIf="job.state === 5" type="button" pButton label="Rerun Job" icon="pi pi-replay" (click)="rerunJob()"
                    [disabled]="!auth.hasPermission(projectId, 'pwrusr')"
                    [pTooltip]="auth.permTip(projectId, 'pwrusr')"></button>
          </div>
        </div>

        <p-tabView styleClass="job-tabview" (onChange)="handleJobTabChange($event)">
          <!-- job step logs -->
          <p-tabPanel header="Steps Logs">
            <app-logs-panel [job]="job"></app-logs-panel>
          </p-tabPanel>
          <!-- TODEL
          <p-tabPanel header="Logs Old">
            <app-log-box [jobId]="selectedJobId"></app-log-box>
          </p-tabPanel>
          -->
          <!-- job flat logs -->
          <p-tabPanel header="Flat Logs">
              <app-simple-logs-panel
                  [visible]="flatLogsPanelVisible"
                  [jobId]="job.id"
                  [topOffset]="350"
                  [initServices]="['server', 'rq', 'scheduler', 'watchdog', 'agent']"></app-simple-logs-panel>
          </p-tabPanel>

          <!-- job details -->
          <p-tabPanel header="Details">
            <table>
              <tr><td colspan="2" style="font-weight: bold; font-size: 1.2em;">Spec</td></tr>
              <tr><td style="width: 11em;">System:</td><td>{{ job.system }}</td><tr>
              <tr><td>Agent Group:</td><td><a routerLink="/agents-groups/{{ job.agents_group_id }}">{{ job.agents_group_name }}</a></td><tr>
              <tr><td>Executor:</td><td>{{ job.executor }}</td><tr>

              <tr><td colspan="2" style="font-weight: bold; font-size: 1.2em; padding-top: 10px;">Execution</td></tr>
              <tr><td>Agent:</td><td><a routerLink="/agents/{{ job.agent_id }}">{{ job.agent_name }}</a></td><tr>
              <tr><td>State:</td><td>{{ getJobState(job) }}</td><tr>
              <tr><td>Completion Status:</td><td><i style="vertical-align: baseline;" [ngClass]="getJobStatusClass(job)"></i> {{ getJobStatus(job) }}</td><tr>

              <tr><td colspan="2" style="font-weight: bold; font-size: 1.2em; padding-top: 10px;">Timing</td></tr>
              <tr><td>Timeout:</td><td>{{ job.timeout }}s</td><tr>
              <tr><td>Created:</td><td>{{ job.created ? (job.created | localtime) : '' }}</td><tr>
              <tr><td>Started:</td><td>{{ job.started ? (job.started | localtime) : '' }}</td><tr>
              <tr><td>Finished:</td><td>{{ job.finished ? (job.finished | localtime) : '' }}</td><tr>
              <tr><td>Completed:</td><td>{{ job.completed ? (job.completed | localtime) : '' }}</td><tr>
              <tr><td>Duration (start -> finish):</td><td>{{ job.duration }}</td><tr>
            </table>
          </p-tabPanel>

          <!-- job data -->
          <p-tabPanel header="Data">
              <h3>Job Data</h3>
              <pre>{{ jobData }}</pre>
          </p-tabPanel>
        </p-tabView>

      </div>
    </div>
  </div>
</div>


<!-- TESTS RESULTS TAB -->

<div *ngIf="activeTabIdx === 1" style="margin-top: 5px;">
    <app-tcr-table [run]="run" #tcrTable></app-tcr-table>
</div>


<!-- ISSUES TAB -->
<div *ngIf="activeTabIdx === 2" style="margin-top: 5px;">

  <div style="display: flex; margin: 15px 5px;">
    <strong style="margin: 2px 30px 0 0; font-size: 1.5em; width: 3em;">Stats</strong>

    <div style="margin-right: 15px; align-self: center;">
      Total: {{ run.issues_total }}
    </div>
    <div style="margin-right: 15px; align-self: center;">
      New: {{ run.issues_new }}
    </div>
  </div>

  <!-- issues filters -->
  <div style="margin: 15px 5px;" class="grid p-fluid">
    <strong style="margin: 2px 20px 0 0; font-size: 1.5em; width: 3em;">Filters</strong>

    <div style="margin-right: 20px;" class="field">
      <label>Type:</label>
      <p-multiSelect
        [options]="issueTypes" [(ngModel)]="filterIssueTypes" optionLabel="name"
        [filter]="false" [scrollHeight]="500" (onChange)="refreshIssues(issuesTable)"
        [style]="{'vertical-align': 'middle'}"></p-multiSelect>
    </div>

    <div style="margin-right: 20px;" class="field">
      <label>Location:</label>
      <input type="text" pInputText [(ngModel)]="filterIssueLocation" (keydown)="filterIssuesKeyDown($event, issuesTable)"/>
    </div>

    <div style="margin-right: 20px;" class="field">
      <label>Message:</label>
      <input type="text" pInputText [(ngModel)]="filterIssueMessage" (keydown)="filterIssuesKeyDown($event, issuesTable)"/>
    </div>

    <div style="margin-right: 20px;" class="field">
      <label>Symbol:</label>
      <input type="text" size="8" pInputText [(ngModel)]="filterIssueSymbol" (keydown)="filterIssuesKeyDown($event, issuesTable)"/>
    </div>

    <div style="margin-right: 20px;" class="field">
      <label>Age:</label>
      <div style="display: flex; align-items: center;">
        <p-inputNumber [showButtons]="true" [(ngModel)]="filterIssueMinAge" [min]="0" [max]="filterIssueMaxAge" size="2"
                       (onInput)="refreshIssues(issuesTable)"></p-inputNumber>
        <div style="width: 1em; text-align: center;"> - </div>
        <p-inputNumber [showButtons]="true" [(ngModel)]="filterIssueMaxAge" [min]="filterIssueMinAge" size="2"
                       (onInput)="refreshIssues(issuesTable)"></p-inputNumber>
      </div>
    </div>

    <div style="align-self: flex-end;" class="field mr-3">
      <p-button label="New" (onClick)="showLastIssuesChanges(issuesTable)" class="ml-1 mr-2" pTooltip="Show changes that happened in the last run"></p-button>
    </div>

    <div class="field">
      <label>Job:</label>
      <input type="text" pInputText [(ngModel)]="filterIssueJob" (keydown)="filterIssuesKeyDown($event, issuesTable)"/>
    </div>

    <div style="display: flex; align-self: flex-end;" class="field">
      <p-button label="Refresh" icon="pi pi-refresh" (onClick)="refreshIssues(issuesTable)" class="ml-4 mr-2"></p-button>
      <p-button label="Reset" icon="pi pi-times-circle" (onClick)="resetIssuesFilter(issuesTable)"></p-button>
    </div>

  </div>


    <p-table #issuesTable
             id="issues"
             [value]="issues"
             [lazy]="true"
             (onLazyLoad)="loadIssuesLazy($event)"
             [paginator]="true"
             [rows]="30"
             [totalRecords]="totalIssues"
             [loading]="loadingIssues"
             [rowsPerPageOptions]="[10,20,30,50,100]"
             [showCurrentPageReport]="true">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 100px;">Job Name</th>
          <th style="width: 60px;">Job ID</th>
          <th style="width: 80px;">Type</th>
          <th style="width: 20%;">Location</th>
          <th style="width: 20%;">Message</th>
          <th style="width: 180px;">Symbol</th>
          <th style="width: 40px;">Age</th>
          <th style="width: 6%;">System</th>
          <th style="width: 5%;">Config</th>
          <th style="width: 5%;">Agent Group</th>
          <th style="width: 5%;">Agent</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-issue>
        <tr>
          <td>{{ issue.job_name }}</td>
          <td><a routerLink="/jobs/{{ issue.job_id }}">{{ issue.job_id }}</a></td>
          <td  style="cursor: pointer;" (dblclick)="filterIssuesByType(issue.issue_type, issuesTable)" [class]="issueTypeToClass(issue.issue_type)">{{issueTypeToTxt(issue.issue_type)}}</td>
          <td class="location">
            <a *ngIf="issue.url" href="{{ issue.url }}" target="blank">
              {{ issue.path }}:{{ issue.line }}:{{ issue.column }}
            </a>
            <span *ngIf="!issue.url">
              {{ issue.path }}:{{ issue.line }}:{{ issue.column }}
            </span>
          </td>
          <td>{{ issue.message }}</td>
          <td style="cursor: pointer;" (dblclick)="filterIssuesBySymbol(issue.symbol, issuesTable)">{{ issue.symbol }}</td>
          <td style="cursor: pointer;" (dblclick)="filterIssuesByAge(issue.age, issuesTable)">{{ issue.age }}</td>
          <td>Ubuntu 18.04</td>
          <td>default</td>
          <td>{{ issue.agents_group_name}}</td>
          <td>{{ issue.agent_name}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorright" let-state>
           Total Records: {{state.totalRecords}}
      </ng-template>
    </p-table>
</div>


<!-- ARTIFACTS TAB -->
<div *ngIf="activeTabIdx === 3" style="margin-top: 5px;">

  <p-table #artifactsTable
           id="artifacts"
           [value]="artifacts"
           [lazy]="true"
           (onLazyLoad)="loadArtifactsLazy($event)"
           [paginator]="true"
           [rows]="30"
           [totalRecords]="totalArtifacts"
           [loading]="loadingArtifacts"
           [rowsPerPageOptions]="[10,20,30,50,100]"
           [showCurrentPageReport]="true">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 60px;">ID</th>
        <th>Path</th>
        <th style="width: 100px;">Size</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-artifact>
      <tr>
        <td>{{ artifact.id }}</td>
        <td><a [href]="artifact.url">{{ artifact.path }}</a></td>
        <td>{{ artifact.size }} B</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
      Total Records: {{state.totalRecords}}
    </ng-template>
  </p-table>
</div>


<!-- ARTIFACTS TAB -->
<div *ngIf="activeTabIdx === 4" style="margin: 10px 0 0 10px;">
    <div *ngFor="let rep of run.report_entries">
        <a [href]="rep.url" target="blank">{{ rep.name }}</a>
    </div>
</div>


<!-- DETAILS TAB -->
<div *ngIf="activeTabIdx === 5" style="margin: 10px 0 0 10px;">

  Created: {{ run.created | localtime }}
  <br>
  Reason: {{ run.reason }}
  <br>
  Run State: {{ run.state }}

</div>
