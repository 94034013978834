<div style="display: flex; margin: 15px 5px;">

    <p-button label="Refresh" icon="pi pi-refresh" (onClick)="refreshAgents(agentsTable)" [style]="{'margin-right': '10px'}"></p-button>
    <p-button label="Authorize" icon="pi pi-check" (onClick)="authorize(agentsTable)"
              [disabled]="!auth.hasPermission(null, 'admin')"
              [pTooltip]="auth.permTip(null, 'admin')"></p-button>

</div>

<p-table #agentsTable
         id="agents"
         [value]="agents"
         [lazy]="true"
         selectionMode="multiple"
         [(selection)]="selectedAgents"
         (onLazyLoad)="loadAgentsLazy($event)"
         [paginator]="true"
         [rows]="30"
         [totalRecords]="totalAgents"
         [loading]="loadingAgents"
         (onRowSelect)="agentSelected($event)"
         [rowsPerPageOptions]="[10,20,30,50,100]"
         [showCurrentPageReport]="true"
         [sortField]="'last_seen'"
         [sortOrder]="-1">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 2em">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="width: 60px;" pSortableColumn="id">ID <p-sortIcon field="id"></p-sortIcon></th>
      <th style="width: 100px;" pSortableColumn="address">Address <p-sortIcon field="address"></p-sortIcon></th>
      <th style="width: 120px;" pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
      <th style="width: 120px;" pSortableColumn="ip_address">IP Address <p-sortIcon field="ip_address"></p-sortIcon></th>
      <th style="width: 10%;" pSortableColumn="last_seen">Last Seen <p-sortIcon field="last_seen"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-agent>
    <tr>
      <td>
        <p-tableCheckbox [value]="agent"></p-tableCheckbox>
      </td>
      <td>{{agent.id}}</td>
      <td>{{agent.address}}</td>
      <td><a routerLink="/agents/{{agent.id}}">{{agent.name}}</a></td>
      <td>{{agent.ip_address}}</td>
      <td>{{ agent.last_seen ? (agent.last_seen | localtime) : '' }}</td>
    </tr>
  </ng-template>
</p-table>
