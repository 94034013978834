<div>
  <p>
    <a href="{{ repoUrl }}" target="blank" style="font-size: 1.5em; font-weight: bold;">{{ repoUrl }}</a>
    <a href="{{ diffUrl }}" target="blank" style="margin-left: 20px;" *ngIf="diffUrl">diff</a>
  </p>

  <div *ngIf="changes && changes.commits">
    <div *ngFor="let c of changes.commits" style="margin-bottom: 10px;">
      <a href="{{ c.http_url || c.url || repoUrl + '/commit/' + c.commit }}" target="blank"><b>{{ c.id.slice(0,8) }}</b></a>
      by <a href="mailto:{{ c.author.email }}">{{ c.author.name }}</a>
      at {{ c.timestamp | localtime }}<br>
      {{ c.message }}
      <div style="font-size: 0.9em;"> <!--  cursor: pointer; (click)="toggleFiles()" -->
        <div>
          <i class="pi pi-angle-right" style="font-weight: bold; color: #0270c6; vertical-align: sub;" *ngIf="showFiles"></i>
          <span *ngIf="c.modified && c.modified.length">modified {{ c.modified.length }}&nbsp;&nbsp;&nbsp;</span>
          <span *ngIf="c.added && c.added.length">added {{ c.added.length }}&nbsp;&nbsp;&nbsp;</span>
          <span *ngIf="c.removed && c.removed.length">removed {{ c.removed.length }}</span>
        </div>
        <div style="margin-left: 18px;" *ngIf="showFiles">
          <div *ngFor="let f of c.added" style="color: #008900;">
            A&nbsp;&nbsp;&nbsp; {{ f }}
          </div>
          <div *ngFor="let f of c.modified" style="color: #0254aa;">
            M&nbsp;&nbsp;&nbsp; {{ f }}
          </div>
          <div *ngFor="let f of c.removed" style="color: #bd0000;">
            D&nbsp;&nbsp;&nbsp; {{ f }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="changes.pull_request">
    Pull Request
    <a href="{{ changes.pull_request.html_url }}" target="blank">#{{ changes.pull_request.number }}</a>
    by <a href="{{ changes.pull_request.user.html_url }}" target="blank">{{ changes.pull_request.user.login }}</a> `
    at {{ changes.pull_request.updated_at | localtime }}<br>
    {{ changes.pull_request.title }}<br>
    branch: {{ changes.pull_request.head.ref }}<br>
    commits: {{ changes.pull_request.commits }}
  </div>

</div>
