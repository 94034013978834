<app-tabbed-page baseLinkUrl="/flows/{{ flowId }}" (tabChanged)="handleTabChange($event)">
    <ng-template pTemplate="header">
        <div style="display: flex; align-items: center;">
            <div style="font-size: 2.5em; font-weight: bold; width: 10em;">
                Flow {{ flow ? flow.label : flowId }}
            </div>
            <p-button label="Refresh" icon="pi pi-refresh" (onClick)="refresh()" styleClass="p-button-sm" [loading]="refreshing"></p-button>
        </div>
    </ng-template>

    <!-- 0. STAGES TAB -->
    <app-tabbed-page-tab label="Stages">
        <div class="grid">
            <div class="col-5">
                <div style="display: flex; flex-direction: column; align-items: flex-end;">
                    <div *ngFor="let n of flatTree" class="level{{n.level - 1}}" style="margin-bottom: 8px; display: flex; align-items: stretch;">
                        <!--
                            <svg *ngIf="n.level > 1" height="30" width="25">
                                <line x1="0" y1="5" x2="20" y2="25" style="stroke:rgb(0,0,0);stroke-width:1" />
                            </svg>
                            -->
                            <app-run-box [projectId]="projectId"
                                         [run]="n.run"
                                         [stage]="n.stage"
                                         [flowId]="flowId"
                                         (stageRun)="onStageRun($event)"
                                         [selectionEnabled]="true"
                                         [selected]="n.selected"
                                         (boxSelect)="changeSelection(n.stage.id)" style="flex: 1;">
                            </app-run-box>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <!-- RUN INFO -->
                <div style="font-size: 2em; margin: -10px 0 0 20px;">
                    <span *ngIf="selectedNode.run">
                        Run <b>{{ selectedNode.run.label || (selectedNode.run.id + '.') }}</b> of stage
                    </span>
                    <span *ngIf="!selectedNode.run">
                        Stage
                    </span>
                    <b>{{ selectedNode.stage.name }}</b>
                </div>
                <p-tabView (onChange)="handleRunTabChange($event)">
                    <!-- Run Overview -->
                    <p-tabPanel header="Overview">
                        <div *ngIf="selectedNode.run" style="display: flex;">
                            <!-- created -->
                            <div class="round-shadow" style="background: var(--greenish); margin-right: 1em;">
                                <div style="font-size: 1.3em; font-weight: bold; margin-bottom: 0.8em;">Created</div>
                                {{ selectedNode.run.created | localtime }}<br>
                                Reason: {{ selectedNode.run.reason }}
                            </div>

                            <!-- started -->
                            <div class="round-shadow" [ngStyle]="{ 'background': (selectedNode.run.started ? 'var(--greenish)' : ''), 'margin-right': '1em' }">
                                <div style="font-size: 1.3em; font-weight: bold; margin-bottom: 0.8em;">Started</div>
                                <ng-container *ngIf="selectedNode.run.started">
                                    {{ selectedNode.run.started | localtime }}
                                </ng-container>
                                <ng-container *ngIf="!selectedNode.run.started">
                                    <ng-container *ngIf="!selectedNode.run.finished">
                                        not yet
                                    </ng-container>
                                    <ng-container *ngIf="selectedNode.run.finished">
                                        never
                                    </ng-container>
                                </ng-container>
                            </div>

                            <!-- completed -->
                            <div class="round-shadow" [ngStyle]="{ 'background': (selectedNode.run.finished ? 'var(--greenish)' : ''), 'margin-right': '1em' }">
                                <div style="font-size: 1.3em; font-weight: bold; margin-bottom: 0.8em;">Completed</div>
                                <div *ngIf="selectedNode.run.finished">
                                    {{ selectedNode.run.finished | localtime }}
                                    <br>
                                    Duration: {{ selectedNode.run.duration }}
                                </div>
                                <div *ngIf="!selectedNode.run.finished">
                                    not yet
                                </div>
                            </div>

                            <!-- processed -->
                            <div class="round-shadow"  [ngStyle]="{ 'background': (selectedNode.run.state === 'processed' ? 'var(--greenish)' : ''), 'margin-right': '1em' }">
                                <div style="font-size: 1.3em; font-weight: bold; margin-bottom: 0.8em;">Processed</div>
                                <div *ngIf="selectedNode.run.state === 'processed'">
                                    {{ selectedNode.run.processed_at | localtime }}
                                </div>
                                <div *ngIf="selectedNode.run.state !== 'processed'">
                                    not yet
                                    <span *ngIf="selectedNode.run.finished">
                                        <br>
                                        <p-button icon="pi pi-arrow-right" label="Force Processing"
                                                  (onClick)="forceRunAnalysis()" styleClass="p-button-danger p-button-outlined p-button-sm"></p-button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="selectedNode.run && selectedNode.run.note"
                             class="mt-3 p-2 w-full shadow-1 border-round-sm"
                             style="background-color: var(--yellow-200);color: var(--yellow-800);">
                            <pre style="white-space: pre-wrap;">{{ selectedNode.run.note }}</pre>
                        </div>

                        <div *ngIf="!selectedNode.run">
                            Run not started
                        </div>
                    </p-tabPanel>

                    <!-- Run Arguments -->
                    <p-tabPanel header="Arguments" *ngIf="selectedNode.run">
                        <div *ngFor="let arg of selectedNode.run.args | keyvalue" style="margin-bottom: 10px;">
                            {{ arg.key }}: {{ arg.value }}
                        </div>
                    </p-tabPanel>

                    <!-- Run Repo Changes -->
                    <p-tabPanel header="Repo Changes" *ngIf="selectedNode.run && selectedNode.run.repo_data">
                        <div *ngFor="let r of selectedNode.run.repo_data">
                            <app-repo-changes [changes]="r" [showFiles]="true"></app-repo-changes>
                        </div>
                    </p-tabPanel>

                    <!-- Run Schema Code Dump: TODO it shows the latest code for old runs so not good -->
                    <p-tabPanel header="Schema Code Dump" *ngIf="selectedNode.run && false">
                        <pre>{{ selectedNode.stage.schema_code }}</pre>
                    </p-tabPanel>

                    <!-- Run Data -->
                    <p-tabPanel header="Data" *ngIf="selectedNode.run">
                        <h3>Run Data</h3>
                        <pre>{{ selectedNode.run.runData }}</pre>
                    </p-tabPanel>

                    <!-- Run Logs -->
                    <p-tabPanel header="Logs" *ngIf="selectedNode.run">
                        <app-simple-logs-panel
                            [visible]="runLogsPanelVisible"
                            [runId]="selectedNode.run.id"
                            [topOffset]="400"
                            [initServices]="['server', 'rq', 'scheduler', 'watchdog']"></app-simple-logs-panel>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </app-tabbed-page-tab>

    <!-- 1. RESULT STATS TAB -->
    <app-tabbed-page-tab label="Results Stats">
        <ng-container *ngIf="flow">
            <app-flow-analysis [flow]="flow">
            </app-flow-analysis>
        </ng-container>
    </app-tabbed-page-tab>

    <!-- 2. RESULT CHARTS TAB -->
    <app-tabbed-page-tab label="Results Charts">
        <ng-container *ngIf="flow">
            <app-flow-charts [flow]="flow">
            </app-flow-charts>
        </ng-container>
    </app-tabbed-page-tab>

    <!-- 3. REPO CHANGES TAB -->
    <app-tabbed-page-tab label="Repo Changes" [visible]="hasFlowCommits" [badge]="repoChangesCount">
        <ng-container *ngIf="hasFlowCommits">
            <app-repo-changes [changes]="flow.trigger"></app-repo-changes>
        </ng-container>
    </app-tabbed-page-tab>

    <!-- 4. ARTIFACTS TAB -->
    <app-tabbed-page-tab label="Artifacts" [visible]="hasFlowArtifacts">
        <ng-container *ngIf="flow && flow.artifacts.public && flow.artifacts.public.count > 0">
            <h3>Public</h3>
            <div style="display: flex;">
                <div>
                    Files: {{ flow.artifacts.public.count }}
                    <br>
                    Total size: {{ humanFileSize(flow.artifacts.public.size) }}
                </div>

                <div style="margin-left: 30px; flex: 1;">
                    <p-table #artifactsTable
                             id="artifacts"
                             [value]="artifacts"
                             [lazy]="true"
                             (onLazyLoad)="loadArtifactsLazy($event)"
                             [paginator]="true"
                             [rows]="30"
                             [totalRecords]="totalArtifacts"
                             [loading]="loadingArtifacts"
                             [rowsPerPageOptions]="[10,20,30,50,100]"
                             [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="overflow-x: hidden;">Name</th>
                                <th style="width: 14em;">Size</th>
                                <th style="width: 6em;">Stage</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-a>
                            <tr>
                                <td><a href="{{ a.url }}">{{a.path}}</a></td>
                                <td>{{ humanFileSize(a.size) }}</td>
                                <td><a href="/runs/{{ a.run_id }}/jobs">{{ a.stage }}</a></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="paginatorright" let-state>
                            Total Records: {{ state.totalRecords }}
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="flow && flow.artifacts.private && flow.artifacts.private.count > 0">
            <h3>Private</h3>
            Files: {{ flow.artifacts.private.count }}
            <br>
            Total size: {{ humanFileSize(flow.artifacts.private.size) }}
        </ng-container>
    </app-tabbed-page-tab>

    <!-- 5. REPORTS TAB -->
    <app-tabbed-page-tab label="Reports" [visible]="hasFlowReports()">
        <ng-container *ngIf="hasFlowReports()">
            <div *ngFor="let rep of flow.report_entries">
                <a [href]="rep.url" target="blank">{{ rep.name }}</a>
            </div>
        </ng-container>
    </app-tabbed-page-tab>

    <!-- 6. STAGES CHART TAB -->
    <app-tabbed-page-tab label="Stages Chart">
        <p-menu #nodeMenu [popup]="true" [model]="nodeMenuItems"></p-menu>
        <p-organizationChart [value]="runsTree">
            <ng-template let-node pTemplate="root">
                <div style="text-align: left;">
                    <div style="font-weight: bold; font-size: 1.3rem;">{{ node.label }}</div>
                    <div>{{node.data.created}}</div>
                    <div>{{node.data.duration}}</div>
                </div>
            </ng-template>
            <ng-template let-node pTemplate="default">
                <div style="text-align: left;">
                    <div *ngIf="node.data.run">
                        <div style="font-weight: bold; font-size: 1.1rem; display: flex; justify-content: space-between;">
                            <div><a routerLink="/runs/{{node.data.run.id}}">{{ node.label }}</a></div>
                            <div style="cursor: pointer;" (click)="showNodeMenu($event, nodeMenu, node)"><i class="pi pi-bars"></i></div>
                        </div>
                        <div>{{node.data.run.created}}</div>
                        <div>{{node.data.run.duration}}</div>
                        <div>jobs: {{node.data.run.jobs_total}}</div>
                        <div>passed: {{node.data.run.tests_passed * 100 / node.data.run.tests_total}}%, {{node.data.run.tests_passed}}/{{node.data.run.tests_total}}</div>
                    </div>
                    <div *ngIf="!node.data.run">
                        <div style="font-weight: bold; font-size: 1.1rem; display: flex; justify-content: space-between;">
                            <div>{{ node.label }}</div>
                            <div style="cursor: pointer;" (click)="showNodeMenu($event, nodeMenu, node)"><i class="pi pi-bars"></i></div>
                        </div>
                        not run
                    </div>
                </div>
            </ng-template>
        </p-organizationChart>
    </app-tabbed-page-tab>

    <!-- 7. ARGUMENTS TAB -->
    <app-tabbed-page-tab label="Arguments">
        <div *ngFor="let section of args" style="margin-bottom: 10px;">
            <span style="font-size: 1.2rem; font-weight: bold;">{{section.name}}</span>
            <table>
                <tr>
                    <th style="width: 20rem;"></th>
                    <th style="width: 20rem;"></th>
                </tr>

                <tr *ngFor="let a of section.args">
                    <td style="text-align: right; padding-right: 10px;">
                        {{a.name}}
                        <br>
                        <span style="font-size: 0.8rem; font-weight: 100;">
                            {{a.description}}
                            <span *ngIf="a.default">
                                <br>Default value is {{a.default}}.
                            </span>
                        </span>
                    </td>
                    <td>
                        {{a.value}}
                    </td>
                </tr>
            </table>
        </div>
    </app-tabbed-page-tab>

    <!-- 8. DATA TAB -->
    <app-tabbed-page-tab label="Data">
        <h3>User Data</h3>
        <app-user-data-panel
            [flowId]="flowId"></app-user-data-panel>
        <h3>Flow Data</h3>
        <pre>{{ flowData }}</pre>
    </app-tabbed-page-tab>

    <!-- 9. LOGS TAB -->
    <app-tabbed-page-tab label="Logs">
        <app-simple-logs-panel
            [visible]="logsPanelVisible"
            [flowId]="flowId"
            [topOffset]="300"
            [initServices]="['server', 'rq', 'scheduler', 'watchdog']"></app-simple-logs-panel>
    </app-tabbed-page-tab>

</app-tabbed-page>
